import { useDispatch, useSelector } from 'react-redux';
import { HeaderProps } from '../../@types/Props';
import { sidebarActions } from '../../store/sidebar';
import LanguageSelector from './LanguageSelector';
import { RootState } from '../../store';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import FrottusIcon from '../../assets/frottus.png';

const Header = ({title}: HeaderProps) => {
  const dispatch = useDispatch();
  const extendedSidebar = useSelector((state: RootState) => state.sidebar.extended);
  
  return (
    <header className='flex flex-row justify-between h-14 select-none'>
      <div className={`flex ${extendedSidebar ? 'w-60' : ''} justify-between`} onClick={() => dispatch(sidebarActions.toggleExtended())}>
        {
          extendedSidebar ? (
            <>
              <img src={FrottusIcon} className='w-10 h-10 mx-2 self-center rounded-md' />
              <h1 className='self-center font-roboto font-semibold text-3xl text-center text-dark-blue w-full'>Frottus</h1>
              <FaChevronLeft className='self-center w-4 h-4 mx-3 my-3 text-sidebar-icon'/> 
            </>
          ) :
          <div className='mx-2 self-center rounded-md hover:cursor-pointer'>
            <img src={FrottusIcon} className='w-10 h-10 self-center rounded-md' />
          </div>
          // <FaChevronRight className='self-center w-5 h-5 ml-4 mr-5 my-3 text-sidebar-icon'/>
        }
      </div>

      <div className='flex-1 bg-dark-blue'>
        <div className='flex justify-between'>
          <div className='flex'>
            <div
              className='px-4 py-4 hover:cursor-pointer justify-center'
              onClick={() => dispatch(sidebarActions.toggle())}
            >
              <div className='w-5 h-0.5 rounded-r-xl rounded-l-xl my-1 bg-gray-200'></div>
              <div className='w-5 h-0.5 rounded-r-xl rounded-l-xl my-1 bg-gray-200'></div>
              <div className='w-5 h-0.5 rounded-r-xl rounded-l-xl my-1 bg-gray-200'></div>
            </div>

            <h1 className='self-center font-roboto text-lg text-white '>{title}</h1>
          </div>
          <LanguageSelector/>
        </div>

      </div>
    </header>
  );
};

export default Header;