import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Notification } from '../@types/Notification';

export interface NotificationsState {
  items: { [id: number] : Notification };
  selectedNotificationId: null | number;
  selectedNotifications: null | Notification[];
}

const initialState: NotificationsState = {
  items: {},
  selectedNotificationId: null,
  selectedNotifications: null,
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    refresh: (state, action: PayloadAction<Notification[]>) => {
      state.items = {};
      action.payload.forEach((item: Notification) => state.items[item.id] = item);
    },
    update: (state, action: PayloadAction<Notification[]>) => {
      action.payload.forEach((item: Notification) => state.items[item.id] = item);
    },
    select: (state, action: PayloadAction<number>) => {
      state.selectedNotificationId = action.payload;
    },
    unSelect: (state) => {
      state.selectedNotificationId = null;
    },
    remove: (state, action: PayloadAction<number>) => {
      delete state.items[action.payload];
    },
    setSelectedNotifications: (state, action: PayloadAction<Notification[] | null>) => {
      state.selectedNotifications = action.payload;
    },
    // addSelectedNotification: (state, action: PayloadAction<Notification>) => {
    //   state.selectedNotifications?.push(action.payload);
    // },
    // removeSelectedNotification: (state, action: PayloadAction<Notification>) => {
    //   state.selectedNotifications = state.selectedNotifications?.filter(x => x.id !== action.payload.id);
    // },
    reset: (state) => {
      state.items = {};
      state.selectedNotificationId = null;
      state.selectedNotifications = null;
    },
  },
})

// Action creators are generated for each case reducer function
export const notificationActions = notificationSlice.actions

export const notificationReducer = notificationSlice.reducer