import { createContext, useContext } from "react";
import { batch, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthenticationContextType, AuthenticationProviderProps } from "../@types/Props";
import { User } from "../@types/User";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { commandActions, deviceActions, eventActions, geofenceActions, groupActions, notificationActions, reportActions, sessionActions, trackActions, userActions } from "../store";

const AuthContext = createContext<AuthenticationContextType | null>(null);

export const AuthProvider = ({children} : AuthenticationProviderProps) => {
  const [user, setUser] = useLocalStorage('user', null);
  const [token, setToken] = useLocalStorage('token', null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logIn = async (data: User) => {
    setUser(data);
    navigate("/map");
  };

  const logOut = () => {
    setUser(null);
    setToken(null);
    batch(() => {
      dispatch(eventActions.reset());
      dispatch(deviceActions.reset());
      dispatch(sessionActions.removeUser());
      dispatch(groupActions.reset());
      dispatch(userActions.reset());
      dispatch(notificationActions.reset());
      dispatch(commandActions.reset());
      dispatch(reportActions.reset());
      dispatch(trackActions.reset());
      dispatch(geofenceActions.reset());
    });
    navigate("/login", { replace: true });
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        logIn,
        logOut
      }}
    >
      {children}
    </AuthContext.Provider>
    );
}

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      'useAuthContext must be used within a AuthProvider'
    );
  }
  const {
    token,
    user,
    logIn,
    logOut,
  } = context;
  return {
    token,
    user,
    logIn,
    logOut,
  };
}