import { Autocomplete, AutocompleteRenderInputParams, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, InputLabelProps, Switch, TextField, useMediaQuery, useTheme } from '@mui/material';
import { ChannelValueType, EventValueType, NotificationFormProps } from '../../@types/Props';
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const types = [
  // {
  //     "type": "commandResult"
  // },
  // {
  //     "type": "deviceOnline"
  // },
  // {
  //     "type": "deviceUnknown"
  // },
  // {
  //     "type": "deviceOffline"
  // },
  // {
  //     "type": "deviceInactive"
  // },
  // {
  //     "type": "queuedCommandSent"
  // },
  // {
  //     "type": "deviceMoving"
  // },
  // {
  //     "type": "deviceStopped"
  // },
  // {
  //     "type": "deviceOverspeed"
  // },
  // {
  //     "type": "deviceFuelDrop"
  // },
  // {
  //     "type": "deviceFuelIncrease"
  // },
  // {
  //     "type": "geofenceEnter"
  // },
  {
      "type": "geofenceExit"
  },
  // {
  //     "type": "alarm"
  // },
  {
      "type": "ignitionOn"
  },
  {
      "type": "ignitionOff"
  },
  // {
  //     "type": "maintenance"
  // },
  // {
  //     "type": "textMessage"
  // },
  // {
  //     "type": "driverChanged"
  // },
  // {
  //     "type": "media"
  // }
];

const notificators = [
  {"type":"web"},
  // {"type":"sms"},
  {"type":"firebase"},
  // {"type":"mail"}
];

const NotificationForm = ({title, subtitle, notification, opened, readonly, close, onSubmit}: NotificationFormProps) => {
  const emptyValues = {
    type: "",
    always: false,
    notificators: "",
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  
  const typeOptions = types.map((value, index) => { 
    return {
      label: `${t(value.type as EventValueType)}`,
      value: value.type
    }
  });

  const notificatorOptions = notificators.map((value, index) => { 
    return {
      label: `${t(value.type as ChannelValueType)}`,
      value: value.type
    }
  });

  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: yup.object({
      type: yup.string().required("Campo obrigatório."),
      always: yup.boolean().required("Campo obrigatório."),
      notificators: yup.string().required("Campo obrigatório."),
    }),
    onSubmit: onSubmit,
  });

  const onDialogClose = (event: object, reason: string) => {
    if (reason !== "backdropClick") close();
  }

  const onCancelButtonClicked = () => {
    formik.resetForm({ values: emptyValues })
    formik.setErrors({});
    close();
  }

  useEffect(() => {
    if (notification !== null && notification !== undefined) {
      formik.setValues({
        type: notification.type ?? '',
        always: notification.always,
        notificators: notification.notificators ?? '',
      })
    }
  }, [notification]);

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen={fullScreen}
      open={opened}
      onClose={onDialogClose}
      aria-labelledby="responsive-dialog-title"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="responsive-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          {subtitle}
          </DialogContentText>
          
          <div className='flex flex-col py-4'>
            <div className='flex flex-col md:flex-row'>
              <Autocomplete
                fullWidth
                autoComplete
                includeInputInList
                id="type-autocomplete"
                isOptionEqualToValue={(option, value) => option.value === value.value && option.label === value.label}
                options={typeOptions}
                onChange={(event, item) => {
                  const type = (item !== undefined ? item?.value : "") as string;
                  formik.setValues({...formik.values, type});
                }}
                defaultValue={readonly || !!notification ? {
                    label: `${t(notification?.type as ChannelValueType)}`,
                    value: notification?.type 
                  } : undefined}
                disabled={readonly}
                renderInput={(params) => (
                  <TextField
                    {...(params as AutocompleteRenderInputParams & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })}
                    label="Tipo"
                    placeholder="Selecione o tipo da notificação"
                    variant="outlined"
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  />
                )}
              />
            </div>
            <div className='flex flex-col md:flex-row'>
              <Autocomplete
                className='pt-2'
                multiple
                fullWidth
                id="notificator-autocomplete"
                isOptionEqualToValue={(option, value) => option.value === value.value && option.label === value.label}
                options={notificatorOptions}
                getOptionLabel={(option) => option.label}
                defaultValue={readonly || !!notification ? notification?.notificators.split(",").map(v => {
                  return {
                    label: `${t(v as ChannelValueType)}`,
                    value: v
                  };
                }) : []}
                disabled={readonly}
                onChange={(event, values) => {
                  let notificators = "";
                  for (let i = 0; i < values.length; i++) {
                    notificators += values[i].value;
                    if (i !== values.length -1)
                      notificators += ",";
                  }
                  formik.setValues({...formik.values, notificators});
                }}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    {...(params as AutocompleteRenderInputParams & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })}
                    label="Canal"
                    placeholder="Selecione os canais de notificação"
                    variant="outlined"
                    error={formik.touched.notificators && Boolean(formik.errors.notificators)}
                    helperText={formik.touched.notificators && formik.errors.notificators}
                  />
                )}
              />
            </div>
            <div className='flex flex-col md:flex-row py-2'>
              <FormGroup>
                <FormControlLabel disabled={readonly} control={<Switch checked={formik.values.always} onClick={() => formik.setValues({...formik.values, always: !formik.values.always})} />} label="Todos os veículos" />
              </FormGroup>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {
            !readonly ?
            <Button variant='text' color='error' onClick={onCancelButtonClicked}>
              CANCELAR
            </Button> : null
          }
          <Button type='submit' onClick={() => {}} variant='contained' color='primary' autoFocus>
            {readonly ? 'FECHAR' : notification ? 'ATUALIZAR' : 'SALVAR'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NotificationForm;