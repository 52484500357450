import { useEffect, useRef, forwardRef, ReactNode } from "react";
import "leaflet-rotatedmarker";
import { Icon } from "leaflet";
import ReactLeafletDriftMarker from "react-leaflet-drift-marker"
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface Props {
  children?: ReactNode;
  deviceId: number;
  rotationAngle: number;
  rotationOrigin: string;
  position: [number, number];
  icon: Icon;
}

const RotatedMarker = forwardRef(({ children, ...props }: Props, forwardRef) => {
  let markerRef = useRef(null);
  const focusedDevice = useSelector((state: RootState) => state.device.focusedDevice)
  const { rotationAngle, rotationOrigin, deviceId } = props;

  useEffect(() => {
    const marker = markerRef.current as any;
    if (marker) {
      marker.setRotationAngle(rotationAngle);
      marker.setRotationOrigin(rotationOrigin);
    }
  }, [rotationAngle, rotationOrigin]);

  useEffect(() => {
    const marker = markerRef.current as any;
    if (marker && focusedDevice !== null && focusedDevice.id === deviceId)
      marker.openPopup();
  }, [focusedDevice]);

  return (
    <ReactLeafletDriftMarker
      duration={1000}
      ref={markerRef}
      {...props}
    >
      {children}
    </ReactLeafletDriftMarker>
  );
});

export default RotatedMarker;