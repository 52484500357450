import { BaseResponseType, CreateCommand, SendCommand, UpdateCommand } from "../@types/Responses";
import { deleteOptions, getOptions, postOptions, putOptions } from "./common";

const endpoint = '/api/commands';

const fetchCommandsByUserId = async (userId: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}?userId=${userId}`, getOptions());
  return response.json();
}

const fetchCommandsByDeviceId = async (deviceId: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}?deviceId=${deviceId}`, getOptions());
  return response.json();
}

const createCommand = async (command: CreateCommand) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, postOptions(JSON.stringify(command)));
  return response.json();
}

const deleteCommand = async (commandId: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/${commandId}`, deleteOptions());
  return response.json();
}

const updateCommand = async (command: UpdateCommand) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, putOptions(JSON.stringify(command)));
  return response.json();
}

const sendCommand = async (command: SendCommand) : Promise<BaseResponseType> => {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/send`, postOptions(JSON.stringify(command)));
  return response.json();
}

export const command = {
  fetchCommandsByUserId,
  fetchCommandsByDeviceId,
  createCommand,
  deleteCommand,
  updateCommand,
  sendCommand,
};