import { useTranslation } from 'react-i18next';
import Header from '../Common/Header';
import MainContentContainer from '../Common/MainContentContainer';
import DeviceRouteMap from './DeviceRouteMap';
import RouteMapSidebar from './RouteMapSidebar';

const RouteMap = () => {
  const { t } = useTranslation();
  return (
    <>

      <Header title={`${t('tracking')} > ${t('routes')}`}/>

      <MainContentContainer>

        <RouteMapSidebar/>
        
        <DeviceRouteMap/>

      </MainContentContainer>
      
    </>
  );
};

export default RouteMap;