import { configureStore } from '@reduxjs/toolkit'
import { deviceActions, deviceReducer } from './devices'
import { eventActions, eventReducer } from './events'
import { groupActions, groupReducer } from './group'
import { sessionActions, sessionReducer } from './session'
import { sidebarActions, sidebarReducer } from './sidebar'
import { userActions, userReducer } from './users'
import { notificationActions, notificationReducer } from './notification'
import { commandActions, commandReducer } from './command'
import { reportActions, reportReducer } from './report'
import { trackActions, trackReducer } from './track'
import { geofenceActions, geofenceReducer } from './geofence'

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    device: deviceReducer,
    event: eventReducer,
    session: sessionReducer,
    group: groupReducer,
    user: userReducer,
    notification: notificationReducer,
    command: commandReducer,
    report: reportReducer,
    track: trackReducer,
    geofence: geofenceReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export { sidebarActions };
export { deviceActions };
export { eventActions };
export { sessionActions };
export { groupActions };
export { userActions };
export { notificationActions };
export { commandActions };
export { reportActions };
export { trackActions };
export { geofenceActions };