import { createContext, useContext } from "react";
import { useDispatch } from "react-redux";
import { GroupContextType, ChildrenProps } from "../@types/Props";
import { api } from "../api/api";
import { groupActions } from "../store";

const GroupContext = createContext<GroupContextType | null>(null);

export const GroupProvider = ({children} : ChildrenProps) => {
  const dispatch = useDispatch();

  const fetchGroups = (userId: number) => {
    api.fetchGroups(userId)
    .then(response => {
      if (response.success)
        dispatch(groupActions.updateMany(response.content));
    })
  }

  return (
    <GroupContext.Provider
      value={{
        fetchGroups,
      }}
    >
      {children}
    </GroupContext.Provider>
    );
}

export const useGroupContext = () => {
  const context = useContext(GroupContext);
  if (!context) {
    throw new Error(
      'useGroupContext must be used within a GroupProvider'
    );
  }
  const {
    fetchGroups,
  } = context;
  return {
    fetchGroups,
  };
}