import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from '../@types/User';

export interface SessionState {
  user: null | User;
  socketOnline: boolean;
}

const initialState: SessionState = {
  user: null,
  socketOnline: false,
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    removeUser: (state) => {
      state.user = null;
    },
    updateSocket: (state, action: PayloadAction<boolean>) => {
      state.socketOnline = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const sessionActions = sessionSlice.actions

export const sessionReducer = sessionSlice.reducer