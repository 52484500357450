import { Geofence } from "../../@types/Geofence";
import { IconButton, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useGeofenceContext } from "../../contexts/GeofenceContext";
import AlertDialog from "../Common/AlertDialog";
import { useState } from "react";
import { geofenceActions } from "../../store";
import { useDispatch } from "react-redux";

type GeofenceCardProps = {
  geofence: Geofence;
}

const GeofenceCard = ({ geofence } : GeofenceCardProps) => {
  const dispatch = useDispatch();
  const { deleteGeofenceById } = useGeofenceContext();
  const [showDeleteGeofence, setShowDeleteGeofence] = useState(false);
  return (
    <div className="hover:bg-gray-200" onClick={() => dispatch(geofenceActions.setSelectedGeofence(geofence))}>
      <div className='h-[1px] bg-gray-200 justify-center'></div>
      <div className='flex py-2 justify-between text-center hover:cursor-pointer'>
        <p className='pl-2 self-center'>{geofence.name}</p>
        <Tooltip placement='left' title='Remover'>
          <IconButton onClick={() => setShowDeleteGeofence(true)}>
            <Delete className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip>
      </div>

      <AlertDialog
        title='Remover geocerca'
        text='Deseja remover a geocerca? Essa ação é irreversível.'
        opened={showDeleteGeofence}
        onAgree={() => {
          deleteGeofenceById(geofence.id, () => setShowDeleteGeofence(false))
        }}
        onDisagree={() => setShowDeleteGeofence(false)}
        onClose={() => setShowDeleteGeofence(false)}
      />
      {/* <div className='h-[1px] bg-gray-200 justify-center'></div>
      <div className='flex py-2 justify-between text-center hover:cursor-pointer'>
        <p className='self-center'>{geofence.name}</p>
        <Tooltip placement='left' title='Remover'>
          <IconButton onClick={() => {}}>
            <Delete className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip>
      </div>
      <div className='h-[1px] bg-gray-200 justify-center'></div>
      <div className='flex py-2 justify-between text-center hover:cursor-pointer'>
        <p className='self-center'>{geofence.name}</p>
        <Tooltip placement='left' title='Remover'>
          <IconButton onClick={() => {}}>
            <Delete className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip>
      </div>
      <div className='h-[1px] bg-gray-200 justify-center'></div>
      <div className='flex py-2 justify-between text-center hover:cursor-pointer'>
        <p className='self-center'>{geofence.name}</p>
        <Tooltip placement='left' title='Remover'>
          <IconButton onClick={() => {}}>
            <Delete className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip>
      </div>
      <div className='h-[1px] bg-gray-200 justify-center'></div>
      <div className='flex py-2 justify-between text-center hover:cursor-pointer'>
        <p className='self-center'>{geofence.name}</p>
        <Tooltip placement='left' title='Remover'>
          <IconButton onClick={() => {}}>
            <Delete className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip>
      </div>
      <div className='h-[1px] bg-gray-200 justify-center'></div>
      <div className='flex py-2 justify-between text-center hover:cursor-pointer'>
        <p className='self-center'>{geofence.name}</p>
        <Tooltip placement='left' title='Remover'>
          <IconButton onClick={() => {}}>
            <Delete className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip>
      </div>
      <div className='h-[1px] bg-gray-200 justify-center'></div>
      <div className='flex py-2 justify-between text-center hover:cursor-pointer'>
        <p className='self-center'>{geofence.name}</p>
        <Tooltip placement='left' title='Remover'>
          <IconButton onClick={() => {}}>
            <Delete className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip>
      </div>
      <div className='h-[1px] bg-gray-200 justify-center'></div>
      <div className='flex py-2 justify-between text-center hover:cursor-pointer'>
        <p className='self-center'>{geofence.name}</p>
        <Tooltip placement='left' title='Remover'>
          <IconButton onClick={() => {}}>
            <Delete className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip>
      </div>
      <div className='h-[1px] bg-gray-200 justify-center'></div>
      <div className='flex py-2 justify-between text-center hover:cursor-pointer'>
        <p className='self-center'>{geofence.name}</p>
        <Tooltip placement='left' title='Remover'>
          <IconButton onClick={() => {}}>
            <Delete className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip>
      </div>
      <div className='h-[1px] bg-gray-200 justify-center'></div>
      <div className='flex py-2 justify-between text-center hover:cursor-pointer'>
        <p className='self-center'>{geofence.name}</p>
        <Tooltip placement='left' title='Remover'>
          <IconButton onClick={() => {}}>
            <Delete className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip>
      </div> */}
    </div>
  )
}

export default GeofenceCard;