import MainContentContainer from '../Common/MainContentContainer';
import Header from '../Common/Header';
import DeviceMap from './DeviceMap';
import MainMapSidebar from './MainMapSidebar';
import { useEffect } from 'react';
import { useAuthContext } from '../../contexts/AuthenticationContext';
import { useDeviceContext } from '../../contexts/DeviceContext';
import { useGroupContext } from '../../contexts/GroupContext';
import { useTranslation } from 'react-i18next';
//import { useTranslationContext } from '../../contexts/TranslationContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import SocketController from '../../SocketController';
import { useCommandContext } from '../../contexts/CommandContext';

const MainMap = () => {
  const commands = useSelector((state: RootState) => state.command.items);
  const { user } = useAuthContext();
  const {t} = useTranslation();
  //const { changeLanguage } = useTranslationContext();
  const { fetchDevicesAndPositions } = useDeviceContext();
  const { fetchCommandsByUserId } = useCommandContext();
  const { fetchGroups } = useGroupContext();

  useEffect(() => {
    if (user === null)
      return;
    
    fetchDevicesAndPositions(user.id);
    fetchGroups(user.id);
  }, []);

  useEffect(() => {
    if (user === null)
      return;
    
    if (Object.entries(commands).length === 0) fetchCommandsByUserId(user.id);
  }, [user, commands]);
  
  return (
    <div className='overflow-hidden'>
      {/* <button onClick={(e) => changeLanguage('en')} value='en'>English</button> */}
      {/* <button onClick={(e) => changeLanguage('pt_BR')} value='pt_BR'>Português</button> */}
      
      <Header title={`${t('tracking')} > ${t('map')}`}/>

      <MainContentContainer>

        <MainMapSidebar/>
        
        <DeviceMap/>

      </MainContentContainer>

      <SocketController/>
    </div>
  );
};

export default MainMap;