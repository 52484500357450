import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { MaterialReactTable, MRT_SortingState, MRT_Virtualizer } from 'material-react-table';
import type { MRT_ColumnDef } from 'material-react-table'; // If using TypeScript (optional, but recommended)
import { Bill } from '../../@types/User';
import { BillTableProps } from '../../@types/Props';
import TableTopToolbarAction from '../Common/TableTopToolbarAction';
import RowActions from '../Common/RowActions';
import TableContainer from '../Common/TableContainer';
//import { ExportToCsv } from 'export-to-csv';
import dayjs from 'dayjs';

const BillTable = memo(({bills, actions}: BillTableProps) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  const columns = useMemo<MRT_ColumnDef<Bill>[]>(
    () => [
      {
        //accessorKey: 'dueDate', //simple recommended way to define a column
        accessorFn: (row) => dayjs(row.dueDate).format('DD/MM/YYYY'),
        id: 'dueDate',
        header: 'Vencimento',
        size: 170,
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorFn: (row) => row.paid ? 'PAGO' : 'PENDENTE',
        header: 'Situação',
        size: 150,
      },
    ],
    [],
  );

  //optionally access the underlying virtualizer instance
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);
  
  const [data, setData] = useState<Bill[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'dueDate', desc: true, }]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setData(bills ?? []);
      setIsLoading(false);
    }
  }, [bills]);

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);
    
  return (
    <TableContainer>
      <MaterialReactTable
        defaultColumn={{
          maxSize: 400,
          minSize: 20,
          size: 200, //default size is usually 180
        }}
        columns={columns}
        data={data}
        enableColumnDragging={false}
        enableGlobalFilter
        enablePagination
        positionPagination='bottom'
        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
        initialState={{ density: 'comfortable' }}
        enableDensityToggle={false}

        //vitualization props
        muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 11.5rem)', minWidth: '60vw' } }}
        enableRowVirtualization
        onSortingChange={setSorting}
        state={{ pagination, isLoading, sorting }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
        rowVirtualizerOptions={{ overscan: 5 }} //optionally customize the row virtualizer
        columnVirtualizerOptions={{ overscan: 2 }} //optionally customize the column virtualizer

        renderTopToolbarCustomActions={() => 
          <TableTopToolbarAction addAction={actions.addAction} exportPdfAction={undefined}/>}

        editDisplayMode='modal' //default
        enableColumnOrdering
        enableEditing
        renderRowActions={({ row, table }) => 
          <RowActions
            deleteAction={() => {
              if (actions.deleteAction !== undefined) actions.deleteAction(row.original as Bill);
            }}
            editAction={() => {
              if (actions.editAction !== undefined) actions.editAction(row.original as Bill);
            }}
            downloadBillAction={() => {
              if (actions.downloadBillAction !== undefined) actions.downloadBillAction(row.original as Bill);
            }}
          />
        }
      />
    </TableContainer>
  );
});

export default BillTable;