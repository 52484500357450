import { createSlice } from '@reduxjs/toolkit'
// import type { PayloadAction } from '@reduxjs/toolkit'

export interface SidebarState {
  show: boolean,
  showTrackingMenu: boolean,
  extended: boolean,
}

const initialState: SidebarState = {
  show: true,
  showTrackingMenu: true,
  extended: false,
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggle: (state: SidebarState) => {
      state.show = !state.show
    },
    toggleShowTrackingMenu: (state: SidebarState) => {
      state.showTrackingMenu = !state.showTrackingMenu
    },
    toggleExtended: (state: SidebarState) => {
      state.extended = !state.extended
    },
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload
    // },
  },
})

// Action creators are generated for each case reducer function
export const sidebarActions = sidebarSlice.actions

export const sidebarReducer = sidebarSlice.reducer