import { useTranslation } from 'react-i18next';
import Header from '../Common/Header';
import MainContentContainer from '../Common/MainContentContainer';
import GeofenceMap from './GeofenceMap';
import GeofenceSidebar from './GeofenceSidebar';

const Geofence = () => {
  const { t } = useTranslation();
  return (
    <>

      <Header title={`${t('tracking')} > ${t('geofences')}`}/>

      <MainContentContainer>
        
        <GeofenceSidebar/>
        
        <GeofenceMap/>

      </MainContentContainer>
      
    </>
  );
};

export default Geofence;