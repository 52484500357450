import { MdMap, MdLogout } from 'react-icons/md';
import { AiFillCar } from 'react-icons/ai';
import { FaRoute, FaCompass, FaClipboardList, FaUser, FaBell, FaVectorSquare, FaChevronDown, FaSearchLocation, FaChevronUp } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthenticationContext';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, sidebarActions } from '../../store';
import { Tooltip } from '@mui/material';

const SidebarIcons = () => {
  const { logOut, user } = useAuthContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showTrackingMenu = useSelector((state: RootState) => state.sidebar.showTrackingMenu);
  const extendedSidebar = useSelector((state: RootState) => state.sidebar.extended);
  const location = useLocation();

  const isSelected = (path: string) => location.pathname === path;

  if (user.isClient) {
    return (
      <>
        <Link to={'/map'}>
          <MdMap className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer'/>
        </Link>

        <Link to={'/notification'}>
          <FaBell className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer'/>
        </Link>

        {/* <Link to={'/map/trip'}>
          <FaRoute className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer'/>
        </Link> */}

        <Link to={'/map/route'}>
          <FaCompass className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer'/>
        </Link>

        <Link to={'/geofence'}>
          <FaVectorSquare className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer'/>
        </Link>

        <MdLogout className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer' onClick={logOut}/>
      </>
    );
  }
  else {
    return (
      <div className={extendedSidebar ? 'w-60' : ''}>
        {/* <Link to={'/dashboard'}>
          <MdSpaceDashboard className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer'/>
        </Link> */}

        {/* RASTREAMENTO */}
        <div className='flex justify-between hover:cursor-pointer hover:bg-gray-dropdown-hover' onClick={() => dispatch(sidebarActions.toggleShowTrackingMenu())}>
          <div className='flex'>
            <FaSearchLocation className='w-6 h-6 mx-3 my-3 text-sidebar-icon'/>
            { extendedSidebar ? <div className='self-center font-roboto'>{t('tracking')}</div> : null }
          </div>
          {
            extendedSidebar ? (
              showTrackingMenu ?
              <FaChevronUp className='self-center w-4 h-4 mx-3 my-3 text-sidebar-icon'/> :
              <FaChevronDown className='self-center w-4 h-4 mx-3 my-3 text-sidebar-icon'/>
            ) : null
          }
        </div>

        {
          showTrackingMenu ?
          <div className='flex flex-col'>
              <Link to={'/user'}>
                <div className={`flex hover:cursor-pointer ${isSelected('/user') ? 'bg-dark-blue-selected hover:bg-dark-blue-selected' : 'hover:bg-gray-dropdown-hover'}`}>
                  <FaUser className='w-5 h-5 ml-6 mr-3 my-3 text-sidebar-icon'/>
                  { extendedSidebar ? <div className='self-center font-roboto'>{t('users')}</div> : null }
                </div>
              </Link>

            <Link to={'/device'}>
              <div className={`flex hover:cursor-pointer ${isSelected('/device') ? 'bg-dark-blue-selected hover:bg-dark-blue-selected' : 'hover:bg-gray-dropdown-hover'}`}>
                <AiFillCar className='w-5 h-5 ml-6 mr-3 my-3 text-sidebar-icon'/>
                { extendedSidebar ? <div className='self-center font-roboto'>{t('vehicles')}</div> : null }
              </div>
            </Link>

            {/* <Link to={'/notification'}>
              <FaBell className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer'/>
            </Link> */}

            <Link to={'/map'}>
              <div className={`flex hover:cursor-pointer ${isSelected('/map') ? 'bg-dark-blue-selected hover:bg-dark-blue-selected' : 'hover:bg-gray-dropdown-hover'}`}>
                <MdMap className='w-5 h-5 ml-6 mr-3 my-3 text-sidebar-icon'/>
                { extendedSidebar ? <div className='self-center font-roboto'>{t('map')}</div> : null }
              </div>
            </Link>

            {/* <Link to={'/map/trip'}>
              <FaRoute className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer'/>
            </Link> */}

            <Link to={'/map/route'}>
              <div className={`flex hover:cursor-pointer ${isSelected('/map/route') ? 'bg-dark-blue-selected hover:bg-dark-blue-selected' : 'hover:bg-gray-dropdown-hover'}`}>
                <FaCompass className='w-5 h-5 ml-6 mr-3 my-3 text-sidebar-icon'/>
                { extendedSidebar ? <div className='self-center font-roboto'>{t('routes')}</div> : null }
              </div>
            </Link>

            <Link to={'/report'}>
              <div className={`flex hover:cursor-pointer ${isSelected('/report') ? 'bg-dark-blue-selected hover:bg-dark-blue-selected' : 'hover:bg-gray-dropdown-hover'}`}>
                <FaClipboardList className='w-5 h-5 ml-6 mr-3 my-3 text-sidebar-icon'/>
                { extendedSidebar ? <div className='self-center font-roboto'>{t('reports')}</div> : null }
              </div>
            </Link>

            {/* <Link to={'/geofence'}>
              <FaVectorSquare className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer'/>
            </Link> */}
          </div>
          : null
        }
        {/* SAIR */}
        <div className='flex hover:cursor-pointer hover:bg-gray-dropdown-hover'>
          <MdLogout className='w-6 h-6 mx-3 my-3 text-sidebar-icon' onClick={logOut}/>
          { extendedSidebar ? <div className='self-center font-roboto'>{t('exit')}</div> : null }
        </div>
        
      </div>
    );
  }
};

export default SidebarIcons;