import { useState } from 'react';
import { AlertDialogProps } from '../../@types/Props';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const AlertDialog = ({title, text, opened, onAgree, onDisagree, onClose}: AlertDialogProps) => {
  return (
    <div>
      <Dialog
        open={opened}
        onClose={onClose}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDisagree} autoFocus color='error'>Não</Button>
          <Button onClick={onAgree} color='primary'>Sim</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;