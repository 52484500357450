// import { useRef } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import './App.css';
import './assets/i18n/i18n'
// import WebSocketProxy from './components/Common/WebSocketProxy';
import { useAuthContext } from './contexts/AuthenticationContext';
// import SocketController from './SocketController';

function App() {
  const {user} = useAuthContext();
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const handleMessage = (event: MessageEvent) => {
    console.log("Received message", event.data);
  };
  
  if (user === null)
    return <Navigate to={'/login'} />
  else if (user !== null)
    return (
      <>
        {/* <SocketController/> */}
        {/* <WebSocketProxy url={`${protocol}//${window.location.host}/api/socket`} onMessage={handleMessage} /> */}
        <Outlet/>
      </>
    )

  return (
    <>
      {/* <SocketController/> */}
      <Outlet/>
    </>
  );
}

export default App;
