import { Device } from '../../@types/Device';
import { getTimeDiff, speedFromKnots } from '../../util/util';
import { RootState, deviceActions } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Battery20, Battery60, BatteryCharging20, BatteryCharging60, BatteryChargingFull, BatteryFull, Bolt, CenterFocusStrong, CenterFocusWeak, Error, FlashOff, Speed, Warning } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EventValueType } from '../../@types/Props';

type DeviceCardProps = {
  device: Device;
};

const DeviceCard = ({device}: DeviceCardProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const focusedDevice = useSelector((state: RootState) => state.device.focusedDevice);
  const pos = device.position;

  const handleFocus = () => {
    if (focusedDevice !== null && focusedDevice.id === device.id)
      dispatch(deviceActions.unfocusDevice())
    else
      dispatch(deviceActions.focusDevice(device))
  }

  return (
    <span className='flex flex-row h-16 w-full my-4'>
      <span className='flex flex-col m-2 rounded-full self-center text-center'>
        <button className='self-center m-2' onClick={handleFocus}>
          <Tooltip title='Focar' placement="top">
          {
            focusedDevice !== null && focusedDevice.id === device.id ?
              <CenterFocusStrong className='w-5 h-5 text-dark-blue-hover  hover:rounded-full'/> :
              <CenterFocusWeak className='w-5 h-5 text-sidebar-icon hover:text-dark-blue hover:rounded-full'/>
          }
          </Tooltip>
        </button>
      </span>

      <span className='w-7/12 self-center text-left font-roboto'>
        <p className='text-xs'>{device.attributes.placa}</p>
        <p className='text-xs'>{device.name.length > 25 ? device.name.slice(0, 25 - 1) + "…" : device.name}</p>
        <p className='text-xs'>{device.attributes.modelo}, {device.attributes.ano}</p>
        <p className='text-xs'>{getTimeDiff(device.lastUpdate)}</p>
      </span>

      <span className='flex flex-row rounded-full self-center text-center'>
        <span className='flex flex-col rounded-full self-center text-center'>
        {
          !!pos && !!pos.attributes.ignition ?
          <Tooltip title='Ligado' placement='top'>
            <IconButton size="small">
              <Bolt fontSize="small" style= {{color: "limegreen"}} />
            </IconButton>
          </Tooltip> :
          <Tooltip title='Desligado' placement='top'>
            <IconButton size="small">
              <Bolt fontSize="small" style= {{color: "red"}} />
            </IconButton>
          </Tooltip>
        }
          <IconButton size='small' className='self-center m-2'  onClick={() => {}}>
            <Tooltip title={`${speedFromKnots(pos?.speed, 'kmh')} km/h`} placement="bottom">
              <Speed fontSize="small" className='w-5 h-5 text-sidebar-icon hover:text-dark-blue hover:rounded-full'/>
            </Tooltip>
          </IconButton>
        </span>
        <span className='flex flex-col rounded-full self-center text-center'>
        {
          !!pos && !!pos.attributes.batteryLevel ?
          <Tooltip title={`Nível de bateria: ${pos.attributes.batteryLevel ?? 0}%`} placement='top'>
            <IconButton size="small">
              {pos.attributes.batteryLevel as number > 70 ? (
                pos.attributes.charge
                  ? (<BatteryChargingFull fontSize="small" className='text-[limegreen]' />)
                  : (<BatteryFull fontSize="small" className='text-[limegreen]' />)
              ) : pos.attributes.batteryLevel as number > 30 ? (
                pos.attributes.charge
                  ? (<BatteryCharging60 fontSize="small" className='text-[orange]' />)
                  : (<Battery60 fontSize="small" className='text-[orange]' />)
              ) : (
                pos.attributes.charge
                  ? (<BatteryCharging20 fontSize="small" className='text-[red]' />)
                  : (<Battery20 fontSize="small" className='text-[red]' />)
              )}
            </IconButton>
          </Tooltip> : null
        }
        {
          !!pos && !!pos.attributes.alarm ?
          <Tooltip title={`Alarme: ${t(pos.attributes.alarm as EventValueType)}`} placement='bottom'>
            <IconButton size="small">
              <Warning fontSize="small" style= {{color: "orange"}} />
            </IconButton>
          </Tooltip> : null
        }
        </span>
      </span>
    </span>
  );
};

export default DeviceCard;