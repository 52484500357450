import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReportContextType, ChildrenProps, ReportOptionsType, TotalGroupDevices, GroupPayroll } from "../@types/Props";
import { reportActions } from "../store";
import { api } from "../api/api";
import { Position } from "../@types/Position";
import { ResponseContentType } from "../@types/Responses";
import { useSnackbarContext } from "./SnackbarContext";
import { Device } from "../@types/Device";
import { useAuthContext } from "./AuthenticationContext";

const defaultReportOptions = [
  {
    label: 'Última Comunicação',
    value: 'lastUpdate'
  },
  {
    label: 'Sem GPS',
    value: 'noGps'
  }
];

const managerReportOption = [
  {
    label: 'Total de veículos por empresa',
    value: 'totalGroupDevices'
  },
  {
    label: 'Posições',
    value: 'positions'
  }
];

const adminReportOption = [
  {
    label: 'Fechamento',
    value: 'groupPayroll'
  },
];

const hoursOptions = [
  {
    label: 'Pelo menos 1 hora',
    value: 1,
  },
  {
    label: 'Pelo menos 2 horas',
    value: 2,
  },
  {
    label: 'Pelo menos 4 horas',
    value: 4,
  },
  {
    label: 'Pelo menos 6 horas',
    value: 6,
  },
  {
    label: 'Pelo menos 12 horas',
    value: 12,
  },
  {
    label: 'Pelo menos 24 horas',
    value: 24,
  },
  {
    label: 'Pelo menos 48 horas',
    value: 48,
  },
  {
    label: 'Pelo menos 72 horas',
    value: 72,
  }
]

const ReportContext = createContext<ReportContextType | null>(null);

export const ReportProvider = ({children} : ChildrenProps) => {
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const [reportType, setReportType] = useState<ReportOptionsType | null>(defaultReportOptions[0]);
  const [reportOptions, setReportOptions] = useState<ReportOptionsType[]>(defaultReportOptions);

  const {
    sendRequest,
    setAlertMessage,
    setMessageSeverity,
    setOpen,
  } = useSnackbarContext();

  const fetchPositionsByDeviceId = (deviceId: number, from: string, to: string) => {
    const request = async () => await api.report.fetchPositionsByDeviceId(deviceId, from, to);
    const onSuccess = (content: ResponseContentType) => dispatch(reportActions.setPositions(content as Position[]));
    sendRequest(request, onSuccess, "");
  }

  const fetchLastUpdateByUserId = (userId: number, hours: number) => {
    const request = async () => await api.report.fetchLastUpdateByUserId(userId, hours);
    const onSuccess = (content: ResponseContentType) => dispatch(reportActions.setDevices(content as Device[]));
    sendRequest(request, onSuccess, "");
  }

  const fetchNoGpsDevices = (userId: number) => {
    const request = async () => await api.report.fetchNoGpsDevicesByUserId(userId);
    const onSuccess = (content: ResponseContentType) => dispatch(reportActions.setDevices(content as Device[]));
    sendRequest(request, onSuccess, "");
  }

  const fetchTotalGroupDevices = (userId: number) => {
    const request = async () => await api.report.fetchTotalGroupDevicesByUserId(userId);
    const onSuccess = (content: ResponseContentType) => dispatch(reportActions.setTotalGroupDevices(content as TotalGroupDevices[]));
    sendRequest(request, onSuccess, "");
  }

  const fetchGroupPayroll = (userId: number, unitCost: number) => {
    if (Number.isNaN(unitCost)) {
      setAlertMessage("Preencha o custo unitário.");
      setMessageSeverity("warning");
      setOpen(true);
      return;
    }
    
    const request = async () => await api.report.fetchGroupPayroll(userId, unitCost);
    const onSuccess = (content: ResponseContentType) => dispatch(reportActions.setGroupPayroll(content as GroupPayroll[]));
    sendRequest(request, onSuccess, "");
  }

  useEffect(() => {
    if (user === null || user === undefined) return;

    if (user.isAdmin) setReportOptions([...defaultReportOptions, ...managerReportOption, ...adminReportOption]);
    else if (user.isManager) setReportOptions([...defaultReportOptions, ...managerReportOption]);
  }, [user])
  return (
    <ReportContext.Provider
      value={{
        reportType,
        setReportType,
        reportOptions,
        hoursOptions,
        fetchPositionsByDeviceId,
        fetchLastUpdateByUserId,
        fetchNoGpsDevices,
        fetchTotalGroupDevices,
        fetchGroupPayroll,
      }}
    >
      {children}
    </ReportContext.Provider>
    );
}

export const useReportContext = () => {
  const context = useContext(ReportContext);
  if (!context) {
    throw new Error(
      'useReportContext must be used within a ReportProvider'
    );
  }
  const {
    reportType,
    setReportType,
    reportOptions,
    hoursOptions,
    fetchPositionsByDeviceId,
    fetchLastUpdateByUserId,
    fetchNoGpsDevices,
    fetchTotalGroupDevices,
    fetchGroupPayroll,
  } = context;
  return {
    reportType,
    setReportType,
    reportOptions,
    hoursOptions,
    fetchPositionsByDeviceId,
    fetchLastUpdateByUserId,
    fetchNoGpsDevices,
    fetchTotalGroupDevices,
    fetchGroupPayroll,
  };
}