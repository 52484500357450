import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, geofenceActions } from '../../store';
import { ChangeEvent, useEffect, useState } from 'react';
import { InputLabelProps, Slider } from '@mui/material';
import Button from '../Common/Button';
import { useAuthContext } from '../../contexts/AuthenticationContext';
import { useDeviceContext } from '../../contexts/DeviceContext';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useGeofenceContext } from '../../contexts/GeofenceContext';
import { Device } from '../../@types/Device';
import { useTranslation } from 'react-i18next';
import { CreateGeofence, CreatePermission } from '../../@types/Responses';
import { usePermissionContext } from '../../contexts/PermissionContext';
import { Geofence } from '../../@types/Geofence';

const marks = [
  {
    value: 50,
    label: '50m',
  },
  {
    value: 100,
    label: '100m',
  },
  {
    value: 150,
    label: '150m',
  },
  {
    value: 200,
    label: '200m',
  },
];

const GeofenceSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const devices = useSelector((state: RootState) => state.device.items);
  const deviceCount = Object.entries(devices).length;
  const radius = useSelector((state: RootState) => state.geofence.radius);

  const { user } = useAuthContext();
  const { fetchDevicesAndPositions } = useDeviceContext();
  const { createGeofence } = useGeofenceContext();
  const { createPermission } = usePermissionContext();
  const [device, setDevice] = useState<Device | null | undefined>(null);
  const [name, setName] = useState<string | undefined>(undefined);

  
  const options = Object.entries(devices).map((value, index) => { 
    return {
      label: `${value[1].attributes.placa} - ${value[1].name} - (#${index})`,
      value: value[1].id
    }
  });
  
  useEffect(() => {
    if (user === null)
      return;
    
    if (deviceCount === 0)
      fetchDevicesAndPositions(user.id);

      return () => {
        dispatch(geofenceActions.reset());
      }
  }, []);

  useEffect(() => {
    if (device === null || device === undefined)
      return;

    dispatch(geofenceActions.setDevice(device))
  }, [device])

  return (
    <div className='m-4 h-auto w-80 select-none'>
      {
        deviceCount === 0 ?
        <Skeleton containerClassName="flex h-16 w-full"/> :
        <Autocomplete
          className='pt-4'
          autoComplete
          includeInputInList
          id="placa-autocomplete"
          isOptionEqualToValue={(option, value) => option.value === value.value && option.label === value.label}
          options={options}
          onChange={(event, item) => setDevice(item !== undefined && item !== null && devices[item.value] !== undefined ? devices[item.value] : null)}
          renderInput={(params) => (
            <TextField {...(params as AutocompleteRenderInputParams & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })} label={t('selectVehicle')} variant="outlined" />
          )}
        />
      }
      <div className='w-full py-4 px-4'>
        <Slider
          defaultValue={100}
          getAriaValueText={(value: number) => `${value}m`}
          valueLabelDisplay="auto"
          step={50}
          marks={marks}
          min={50}
          max={200}
          onChange={(event: Event, value: number | number[]) => dispatch(geofenceActions.setRadius(value as number))}
        />
      </div>

      <div className='w-full pb-4'>
        <TextField
          fullWidth
          variant="outlined"
          label={t('geofenceName')}
          id="geofence-name"
          name="geofence-name"
          type="text"
          onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setName(event.target.value)}
          error={name !== undefined && name.length === 0}
          helperText={name !== undefined && name.length === 0 ? t('requiredField') : ""}
        />
      </div>

      <Button
        action={() => {
          createGeofence({
            "name": name as string,
            "area": `CIRCLE (${device?.position?.latitude} ${device?.position?.longitude}, ${radius})`
          } as CreateGeofence,
            (content) => createPermission({
              deviceId: device?.id,
              geofenceId: (content as Geofence)?.id,
            } as CreatePermission,
            (content) => dispatch(geofenceActions.setShowSettings(false))
            )
          );
        }}
        label={t('createGeofence')}
        disabled={name === undefined || name.length === 0 || device === null || device === undefined || device.position === null || device.position === undefined}
      />

      <div className='py-2'></div>

      <Button
        action={() => dispatch(geofenceActions.setShowSettings(false))}
        label={t('return')}
      />
    </div>
  );
};

export default GeofenceSettings;