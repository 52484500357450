
const Support = () => {

  return (
    <div className="p-4">
      <h1 className="font-bold text-lg">Fale conosco</h1>
      <p>Para entrar em contato conosco, envie um e-mail para <a href="mailto:support@frottus.com.br">support@frottus.com.br.</a></p>  
    </div>
  );
};

export default Support;
