import { AddCircle, AddCircleOutline, Description, FileDownload, MoreVert, PictureAsPdf, Refresh } from '@mui/icons-material';
import { Box, Button, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { MdAddCircleOutline } from 'react-icons/md';
import { TableTopToolbarActionProps } from '../../@types/Props';
import { useState } from 'react';

const ITEM_HEIGHT = 48;

const TableTopToolbarAction = ({updateAction, addAction, exportPdfAction, exportCsvAction}: TableTopToolbarActionProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElMain, setAnchorElMain] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMain = Boolean(anchorElMain);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMain = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMain(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMain = () => {
    setAnchorElMain(null);
  };

  const anyExportAction = !!exportPdfAction || !!exportCsvAction;

  return (
    <>
    {
      fullScreen ?
      <>
        <IconButton
          aria-label="more"
          id="main-button"
          aria-controls={openMain ? 'main-menu' : undefined}
          aria-expanded={openMain ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClickMain}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="main-menu"
          MenuListProps={{
            'aria-labelledby': 'main-button',
          }}
          anchorEl={anchorElMain}
          open={openMain}
          onClose={handleCloseMain}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {
            !!updateAction ?
            <MenuItem
              key={0}
              onClick={updateAction}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Refresh className='text-sidebar-icon w-4' />
              </ListItemIcon>
              Atualizar
            </MenuItem> : null
          }
          {
            !!addAction ?
            <MenuItem
              key={1}
              onClick={addAction}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <AddCircleOutline className='text-sidebar-icon w-4' />
              </ListItemIcon>
              Adicionar
            </MenuItem> : null
          }
          {
            !!exportPdfAction ?
            <MenuItem
              key={2}
              onClick={exportPdfAction}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <PictureAsPdf className='text-sidebar-icon w-4' />
              </ListItemIcon>
              PDF
            </MenuItem> : null
          }
          {
            !!exportCsvAction ?
            <MenuItem
              key={3}
              onClick={exportCsvAction}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Description className='text-sidebar-icon w-4' />
              </ListItemIcon>
              CSV
            </MenuItem> : null
          }
        </Menu>
      </> :
      <Box sx={{ display: 'flex', gap: '0.2rem', flexWrap: 'wrap' }}>
        {
          !!updateAction ?
          <Tooltip title='Atualizar' placement='bottom' >
            <IconButton
              color='primary'
              onClick={updateAction}
            >
              <Refresh />
            </IconButton>
          </Tooltip> : null
        }
        {
          !!addAction ?
          <Tooltip title='Adicionar' placement='bottom' >
            <IconButton
              color='primary'
              onClick={addAction}
            >
              <AddCircleOutline />
            </IconButton>
          </Tooltip> : null
        }
        {
          anyExportAction ?
          <>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              color='primary'
            >
              <FileDownload />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                },
              }}
            >
              {
                !!exportPdfAction ?
                <MenuItem
                  key={0}
                  onClick={exportPdfAction}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <PictureAsPdf className='text-sidebar-icon w-4' />
                  </ListItemIcon>
                  PDF
                </MenuItem> : null
              }
              {
                !!exportCsvAction ?
                <MenuItem
                  key={1}
                  onClick={exportCsvAction}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <Description className='text-sidebar-icon w-4' />
                  </ListItemIcon>
                  CSV
                </MenuItem> : null
              }
            </Menu>
          </> : null
        }
      </Box>
    }
    </>
  );
};

export default TableTopToolbarAction;