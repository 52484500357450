import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Position } from '../@types/Position';

export interface TracksState {
  play: boolean;
  pause: boolean;
  stop: boolean;
  repeat: boolean;
  increment: number;
  current: Position | null;
  maxCursor: number;
  tape: Position[] | [];
  cursor: number;
}

const initialState: TracksState = {
  play: false,
  pause: false,
  stop: true,
  repeat: false,
  increment: 0,
  current: null,
  maxCursor: 0,
  tape: [],
  cursor: 0,
}

export const trackSlice = createSlice({
  name: 'track',
  initialState,
  reducers: {
    play: (state) => {
      state.play = true;
      state.pause = false;
      state.stop = false;
    },
    pause: (state) => {
      state.play = false;
      state.pause = true;
      state.stop = false;
    },
    stop: (state) => {
      state.play = false;
      state.pause = false;
      state.stop = true;
      state.current = state.tape[0] ?? null;
      state.increment = 1;
      state.cursor = 0;
    },
    toggleRepeat: (state) => {
      state.repeat = !state.repeat;
    },
    fastFoward: (state) => {
      state.play = state.increment !== -1;
      state.pause = state.increment === -1;
      state.stop = false;
      state.increment += 1;
    },
    fastRewind: (state) => {
      state.play = state.increment !== 1;
      state.pause = state.increment === 1;
      state.stop = false;
      state.increment -= 1;
    },
    updateCurrent: (state) => {
      const nextCurrent = state.tape[state.cursor];
      state.current = state.play && nextCurrent !== undefined ? state.tape[state.cursor] : state.current;
      if (state.cursor >= state.maxCursor && state.increment > 0) {
        state.cursor = state.play && state.repeat ? 0 : state.cursor;
      }
      else if (state.cursor <= 0 && state.increment < 0) {
        state.cursor = state.play && state.repeat ? state.maxCursor : state.cursor
      } else {
        state.cursor = state.play ? state.cursor + state.increment : state.cursor;
      }
    },
    setTape: (state, action: PayloadAction<Position[]>) => {
      state.tape = action.payload
      state.maxCursor = action.payload.length - 1;
      state.increment = 1;
      state.cursor = 0;
      state.current = action.payload[0] ?? null;
    },
    reset: (state) => {
      state.play = false;
      state.pause = false;
      state.stop = true;
      state.repeat = false;
      state.increment = 0;
      state.current = null;
      state.maxCursor = 0;
      state.tape = [];
      state.cursor = 0;
    },
  },
})

// Action creators are generated for each case reducer function
export const trackActions = trackSlice.actions

export const trackReducer = trackSlice.reducer