import { createContext, useContext } from "react";
import { PermissionContextType, ChildrenProps } from "../@types/Props";
import { api } from "../api/api";
import { CreatePermission, DeletePermission, ResponseContentType } from "../@types/Responses";
import { useSnackbarContext } from "./SnackbarContext";

const PermissionContext = createContext<PermissionContextType | null>(null);

export const PermissionProvider = ({children} : ChildrenProps) => {
  const {
    sendRequest,
  } = useSnackbarContext();

  const createPermission = (createPermission: CreatePermission, callback: (content: ResponseContentType) => void) => {
    const request = async () => await api.permission.createPermission(createPermission);
    const onSuccess = (content: ResponseContentType) => {
      if (callback)
        callback(content);
    }
    sendRequest(request, onSuccess, "Permissões alteradas com sucesso");
  }

  const deletePermission = (deletePermission: DeletePermission, callback: (content: ResponseContentType) => void) => {
    const request = async () => await api.permission.deletePermission(deletePermission);
    const onSuccess = (content: ResponseContentType) => {
      if (callback)
        callback(content);
    }
    sendRequest(request, onSuccess, "Permissões alteradas com sucesso");
  }

  return (
    <PermissionContext.Provider
      value={{
        createPermission,
        deletePermission,
      }}
    >
      {children}
    </PermissionContext.Provider>
    );
}

export const usePermissionContext = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error(
      'usePermissionContext must be used within a PermissionProvider'
    );
  }
  const {
    createPermission,
    deletePermission,
  } = context;
  return {
    createPermission,
    deletePermission,
  };
}