import { createContext, useContext } from "react";
import { useDispatch } from "react-redux";
import { NotificationContextType, ChildrenProps, NotificationFormValue } from "../@types/Props";
import { notificationActions } from "../store";
import { api } from "../api/api";
import { Notification } from "../@types/Notification";
import { CreateNotification, UpdateNotification, ResponseContentType, TestNotification } from "../@types/Responses";
import { useSnackbarContext } from "./SnackbarContext";

const NotificationContext = createContext<NotificationContextType | null>(null);

export const NotificationProvider = ({children} : ChildrenProps) => {
  const dispatch = useDispatch();
  const {
    sendRequest,
  } = useSnackbarContext();
  
  const generateUpdateNotification = (notification: Notification, values: NotificationFormValue) => {
    const newNotification = {
      id: notification.id,
      attributes: notification.attributes,
      calendarId: notification.calendarId ?? 0,
      commandId: notification.commandId ?? 0,

      always: values.always,
      notificators: values.notificators,
      type: values.type,
    } as UpdateNotification;

    return newNotification;
  }

  const fetchNotifications = () => {
    api.notification.fetchNotifications()
    .then(response => {
      if (response.success)
        dispatch(notificationActions.refresh(response.content as Notification[]));
    })
  };

  const fetchNotificationsByUserId = (userId: number) => {
    api.notification.fetchNotificationsByUserId(userId)
    .then(response => {
      if (response.success)
        dispatch(notificationActions.refresh(response.content as Notification[]));
    })
  };

  const fetchNotificationsSelectedUser = (userId: number) => {
    api.notification.fetchNotificationsByUserId(userId)
    .then(response => {
      if (response.success)
        dispatch(notificationActions.setSelectedNotifications(response.content as Notification[]));
    })
  };

  const fetchNotificationsSelectedDevice = (deviceId: number) => {
    api.notification.fetchNotificationsByDeviceId(deviceId)
    .then(response => {
      if (response.success)
        dispatch(notificationActions.setSelectedNotifications(response.content as Notification[]));
    })
  };

  const createNotification = async (notif: CreateNotification, callback?: () => void) => {
    const request = async () => await api.notification.createNotification(notif);
    const onSuccess = (content: ResponseContentType) => {
      fetchNotifications();
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Notificação criada com sucesso.");
  }

  const deleteNotificationById = async (notificationId: number, callback?: () => void) => {
    const request = async () => await api.notification.deleteNotification(notificationId);
    const onSuccess = (content: ResponseContentType) => {
      fetchNotifications();
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Notificação removida com sucesso.");
  }

  const updateNotificationById = async (notification: UpdateNotification, callback?: () => void) => {
    const request = async () => await api.notification.updateNotificationById(notification);
    const onSuccess = (content: ResponseContentType) => {
      fetchNotifications();
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Notificação atualizada com sucesso.");
  }

  const sendWebNotificationTest = async (notification: TestNotification, callback?: () => void) => {
    const request = async () => await api.notification.sendWebNotificationTest(notification);
    const onSuccess = (content: ResponseContentType) => {
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Notificação de teste enviada com sucesso.");
  }

  const sendFirebaseNotificationTest = async (notification: TestNotification, callback?: () => void) => {
    const request = async () => await api.notification.sendFirebaseNotificationTest(notification);
    const onSuccess = (content: ResponseContentType) => {
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Notificação de teste enviada com sucesso.");
  }

  return (
    <NotificationContext.Provider
      value={{
        generateUpdateNotification,
        fetchNotifications,
        fetchNotificationsByUserId,
        fetchNotificationsSelectedUser,
        fetchNotificationsSelectedDevice,
        createNotification,
        deleteNotificationById,
        updateNotificationById,
        sendWebNotificationTest,
        sendFirebaseNotificationTest,
      }}
    >
      {children}
    </NotificationContext.Provider>
    );
}

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'useNotificationContext must be used within a NotificationProvider'
    );
  }
  const {
    generateUpdateNotification,
    fetchNotifications,
    fetchNotificationsByUserId,
    fetchNotificationsSelectedUser,
    fetchNotificationsSelectedDevice,
    createNotification,
    deleteNotificationById,
    updateNotificationById,
    sendWebNotificationTest,
    sendFirebaseNotificationTest,
  } = context;
  return {
    generateUpdateNotification,
    fetchNotifications,
    fetchNotificationsByUserId,
    fetchNotificationsSelectedUser,
    fetchNotificationsSelectedDevice,
    createNotification,
    deleteNotificationById,
    updateNotificationById,
    sendWebNotificationTest,
    sendFirebaseNotificationTest,
  };
}