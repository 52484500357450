import { createContext, useContext, useState } from "react";
import { ChildrenProps, SnackbarContextType } from "../@types/Props";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { ResponseContentType, BaseResponseType } from "../@types/Responses";
import { User } from "../@types/User";

const SnackbarContext = createContext<SnackbarContextType | null>(null);

export const SnackbarProvider = ({children} : ChildrenProps) => {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageSeverity, setMessageSeverity] = useState('success');

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const sendRequest = async (
    request: () => Promise<BaseResponseType>, 
    onSuccess: (content: ResponseContentType) => void,
    successMessage?: string
    ) => {
    try {
      const response = await request();

      if (response.success) {
        onSuccess(response.content);
        if (successMessage) {
          setMessageSeverity('success');
          setAlertMessage(successMessage);
          setOpen(true);
        }
      }
      else {
        setMessageSeverity('error');
        setAlertMessage(response.errors[0])
        setOpen(true);
      }
    } catch (error) {
      setMessageSeverity('error');
      setAlertMessage(error as string);
      setOpen(true);
    }
  }

  return (
    <SnackbarContext.Provider
      value={{
        sendRequest,
        setOpen,
        setAlertMessage,
        setMessageSeverity
      }}
    >
      {children}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right',}}>
        <Alert onClose={handleClose} severity={messageSeverity as AlertColor} sx={{ width: '100%' }}>
        {alertMessage}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
    );
}

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error(
      'useSnackbarContext must be used within a SnackbarProvider'
    );
  }
  const {
    sendRequest,
    setOpen,
    setAlertMessage,
    setMessageSeverity
  } = context;
  return {
    sendRequest,
    setOpen,
    setAlertMessage,
    setMessageSeverity
  };
}