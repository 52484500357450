import SearchDevice from './SearchDevice';
import DeviceCountCard from './DeviceCountCard';
import SidebarIcons from '../Common/SidebarIcons';
import DeviceList from './DeviceList';
import type { RootState } from '../../store';
import { useSelector } from 'react-redux';

const MainMapSidebar = () => {
  const show = useSelector((state: RootState) => state.sidebar.show)
  
  return (
    <>
      <aside className='border-r border-gray-200'>
        <SidebarIcons/>
      </aside>

      <aside className={show ? `bg-white` : `bg-white hidden`}>
        <SearchDevice/>

        <DeviceCountCard/>

        <DeviceList/>
      </aside>
    </>
  );
};

export default MainMapSidebar;