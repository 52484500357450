import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import DeviceCard from './DeviceCard';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { sortPlaca } from '../../util/util';

const DeviceList = () => {
  const filteredDevices = useSelector((state: RootState) => state.device.filteredDevices);
  const devices = useSelector((state: RootState) => state.device.items);
  const devicesList = Object.entries(devices).map(x => x[1]).sort(sortPlaca);
  const devicesCount = devicesList.length;

  return (
    <div className='overflow-y-auto select-none' style={{
      height: 'calc(100vh - 14.8rem)'
    }}>
      {
        devicesCount === 0 ?
          Object.entries([1,2,3,4,5,6,7,8,9,10])
            .map((value, index) => <Skeleton key={index} containerClassName="flex flex-row h-16 w-full mb-2"/>)
        :
          (filteredDevices === null ? devicesList : filteredDevices).map((value, index) => <DeviceCard key={index} device={value} />)
      }
    </div>
  );
};

export default DeviceList;