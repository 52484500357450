import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthenticationContext';

const NotFound = () => {
  const { user } = useAuthContext();

  if (user !== null)
    return <Navigate to={'/map'} />
  else
    return <Navigate to={'/login'} />
};

export default NotFound;