import React, { Component, ErrorInfo, ReactNode } from "react";
import WarningIcon from '@mui/icons-material/Warning';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorInfo: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorInfo: '',
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorInfo: '' };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      hasError: true,
      errorInfo: `${error.message}`
    })
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col text-center">
          <WarningIcon className="self-center"/>
          <h1>Desculpe, ocorreu um erro. Recarregue a página novamente</h1>
          <p>{this.state.errorInfo}</p>
        </div>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;