import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from '../@types/User';

export interface UsersState {
  items: { [id: number] : User };
  selectedUserId: null | number;
  selectedUsers: null | User[];
}

const initialState: UsersState = {
  items: {},
  selectedUserId: null,
  selectedUsers: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    refresh: (state, action: PayloadAction<User[]>) => {
      state.items = {};
      action.payload.forEach((item: User) => state.items[item.id] = item);
    },
    update: (state, action: PayloadAction<User[]>) => {
      action.payload.forEach((item: User) => state.items[item.id] = item);
    },
    select: (state, action: PayloadAction<number>) => {
      state.selectedUserId = action.payload;
    },
    unSelect: (state) => {
      state.selectedUserId = null;
    },
    remove: (state, action: PayloadAction<number>) => {
      delete state.items[action.payload];
    },
    setSelectedUsers: (state, action: PayloadAction<User[] | null>) => {
      state.selectedUsers = action.payload;
    },
    reset: (state) => {
      state.items = {};
      state.selectedUserId = null;
      state.selectedUsers = null;
    },
  },
})

// Action creators are generated for each case reducer function
export const userActions = userSlice.actions

export const userReducer = userSlice.reducer