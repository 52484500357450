import SidebarIcons from '../Common/SidebarIcons';

const UserSidebar = () => {

  return (
    <aside className='border-r border-gray-200'>
      <SidebarIcons/>
    </aside>
  );
};

export default UserSidebar;