import React from "react";
import { MaskProps } from "../../@types/Props";
import { IMaskInput } from "react-imask";

export const TextMaskPhone = React.forwardRef<HTMLElement, MaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(00) 0000-0000"
        // definitions={{
        //   '#': /[1-9]/,
        // }}
        //inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

export const TextMaskMoneyReal = React.forwardRef<HTMLElement, MaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="R$ 0,00"
        // definitions={{
        //   '#': /[0-9]+/,
        // }}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      />
    );
  },
);

export const TextMaskCellPhone = React.forwardRef<HTMLElement, MaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(00) 00000-0000"
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

export const TextMaskFullCellPhone = React.forwardRef<HTMLElement, MaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="+00 (00) 00000-0000"
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);
  
export const TextMaskCnpj = React.forwardRef<HTMLElement, MaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00.000.000/0000-00"
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

export const TextMaskCpf = React.forwardRef<HTMLElement, MaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000.000.000-00"
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);
  
export const TextMaskCep = React.forwardRef<HTMLElement, MaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00.000-000"
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);