import { ReactNode } from 'react';

const MainContentContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className='flex' style={{
      height: 'calc(100vh - 3.5rem)'
    }}>
      {children}
    </div>
  );
};

export default MainContentContainer;