import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Stack, Switch, TextField, useMediaQuery, useTheme } from '@mui/material';
import { BillFormProps } from '../../@types/Props';
import { useFormik } from "formik";
import * as yup from "yup";
import { FileUpload } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

const BillForm = ({title, subtitle, bill, opened, close, onSubmit}: BillFormProps) => {
  const emptyValues = {
    file: new File([], ''),
    url: '',
    dueDate: dayjs().toISOString(),
    paid: false,
  };
  const [dueDate, setDueDate] = useState<Dayjs | null>(dayjs());
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: yup.object({
      url: yup.string().notRequired(),
      dueDate: yup.date().notRequired(),
      paid: yup.boolean().notRequired(),
    }),
    onSubmit: onSubmit,
  });

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null || e.target.files === undefined)
      return;
    
    const file = e.target.files[0];
    if (file)
      formik.setValues({...formik.values, url: file.name, file: file})
  };

  const onDialogClose = (event: object, reason: string) => {
    if (reason !== "backdropClick") close();
  }

  const onCancelButtonClicked = () => {
    const now = dayjs();
    setDueDate(now);
    formik.resetForm({ values: {
      ...emptyValues,
      dueDate: now.toString(),
    }})
    formik.setErrors({});
    close();
  }

  useEffect(() => {
    if (bill !== null && bill !== undefined) {
      setDueDate(dayjs(bill.dueDate))
      formik.setValues({
        file: new File([], ''),
        url: bill.url ?? '',
        dueDate: bill.dueDate ?? '',
        paid: bill.paid as boolean,
      })
    }
  }, [bill]);

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen={fullScreen}
      open={opened}
      onClose={onDialogClose}
      aria-labelledby="responsive-dialog-title"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="responsive-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          {subtitle}
          </DialogContentText>
          
          <div className='flex flex-col py-4'>
            <div className='flex flex-col md:flex-row'>
              <div className='w-full md:w-2/5'>
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      label='Selecione a data de vencimento'
                      format='DD/MM/YYYY'
                      //ampm={false}
                      value={dueDate}
                      onChange={value => {
                        setDueDate(value);
                        formik.setValues({...formik.values, dueDate: value?.toISOString() as string})
                      }}
                    />
                  </Stack>
              </div>

              <div className='px-4 self-center'>
                <Button
                  disabled={!!bill}
                  className='h-12 self-center'
                  size='small'
                  variant="contained"
                  component="label"
                  // color='secondary'
                >
                  Carregar boleto
                  <input
                    accept='.pdf'
                    type='file'
                    hidden
                    onChange={(e) => handleFileUpload(e)}
                  />
                </Button>
              </div>
              <div className='md:pl-4 pt-2'>
                <FormGroup>
                  <FormControlLabel control={<Switch checked={formik.values.paid} onClick={() => formik.setValues({...formik.values, paid: !formik.values.paid})} />} label="Pago" />
                </FormGroup>
              </div>
            </div>
            {
              formik.values.url.length > 0 ?
              <div className='flex flex-col md:flex-row pt-2 select-none'>
                <FileUpload/>
                <p>{formik.values.url}</p>
              </div> : null
            }
          </div>
          
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='error' onClick={onCancelButtonClicked}>
            CANCELAR
          </Button>
          <Button type='submit' onClick={() => {}} variant='contained' color='primary' autoFocus>
            {bill ? 'ATUALIZAR' : 'SALVAR'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BillForm;