import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Position } from '../@types/Position';
import { Device } from '../@types/Device';
import { GroupPayroll, TotalGroupDevices } from '../@types/Props';

export interface ReportsState {
  positions: Position[];
  devices: Device[];
  totalGroupDevices: TotalGroupDevices[];
  groupPayroll: GroupPayroll[]
}

const initialState: ReportsState = {
  positions: [],
  devices: [],
  totalGroupDevices: [],
  groupPayroll: [],
}

export const reportslice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setPositions: (state, action: PayloadAction<Position[]>) => {
      state.positions = action.payload;
    },
    setDevices: (state, action: PayloadAction<Device[]>) => {
      state.devices = action.payload;
    },
    setTotalGroupDevices: (state, action: PayloadAction<TotalGroupDevices[]>) => {
      state.totalGroupDevices = action.payload;
    },
    setGroupPayroll: (state, action: PayloadAction<GroupPayroll[]>) => {
      state.groupPayroll = action.payload;
    },
    clearpositions: (state) => {
      state.positions = [];
    },
    clearDevices: (state) => {
      state.devices = [];
    },
    clearTotalGroupDevices: (state) => {
      state.totalGroupDevices = [];
    },
    clearGroupPayroll: (state) => {
      state.groupPayroll = [];
    },
    reset: (state) => {
      state.positions = [];
      state.devices = [];
      state.totalGroupDevices = [];
      state.groupPayroll = [];
    },
  },
})

// Action creators are generated for each case reducer function
export const reportActions = reportslice.actions

export const reportReducer = reportslice.reducer