import { AiOutlinePoweroff } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux';
import { RootState, deviceActions } from '../../store';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Tooltip } from '@mui/material';

const DeviceCountCard = () => {
  const dispatch = useDispatch();
  const devices = useSelector((state: RootState) => state.device.items);
  const filteredDevices = useSelector((state: RootState) => state.device.filteredDevices);
  const ignitionFilter = useSelector((state: RootState) => state.device.ignitionFilter);
  const devicesArray = Object.entries(devices).map(x => x[1]);
  const devicesCount = filteredDevices === null ? devicesArray.length : filteredDevices?.length;
  const devicesOn = (filteredDevices === null ? devicesArray : filteredDevices).filter(d => d.position?.attributes?.ignition).length;
  const devicesOff = devicesCount - devicesOn;
  
  return (
    devicesCount === 0 && filteredDevices === null ?
    <div className='flex flex-column m-4 h-5 justify-between font-roboto select-none'>
      <Skeleton containerClassName="flex-1"/>
    </div>
    :
    <div className='flex flex-column m-4 h-5 justify-between font-roboto select-none'>
      <p>{`${devicesCount} ${(devicesCount > 1 ? 'rastreadores' : 'rastreador')}`}</p>
      
      <div className='flex flex-row justify-around'>
        <Tooltip
          className='hover: cursor-pointer'
          title='Ligado'
          placement="bottom"
          onClick={() => dispatch(deviceActions.setIgnitionFilter(ignitionFilter === null || !ignitionFilter ? true : null))}
        >
          <div className='flex flex-column px-2'>
            <p>{devicesOn}</p>
            <AiOutlinePoweroff
              className={`h-5 w-5 text-limegreen self-center ml-2 ${ignitionFilter ? 'border rounded' : ''}`}
            />
          </div>
        </Tooltip>

        <Tooltip
          className='hover: cursor-pointer'
          title='Desligado'
          placement="bottom"
          onClick={() => dispatch(deviceActions.setIgnitionFilter(ignitionFilter === null || ignitionFilter ? false : null))}
        >
          <div className='flex flex-column px-2'>
            <p>{devicesOff}</p>
            <AiOutlinePoweroff
              className={`h-5 w-5 text-red-original self-center ml-2 ${ignitionFilter !== null && !ignitionFilter ? 'border rounded' : ''}`}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default DeviceCountCard;