import { useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import L from 'leaflet';
import { useEffect } from 'react';
import { Device } from '../../@types/Device';

type GeofenceMapDeviceFocusProps = {
  focusedDevice: Device;
}

const GeofenceMapDeviceFocus = ({focusedDevice}: GeofenceMapDeviceFocusProps) => {
  const map = useMap();

  useEffect(() => {
    if (focusedDevice !== null && focusedDevice.position !== null && focusedDevice.position !== undefined) {
      const pos = focusedDevice.position;
      const focusedMarkerBounds = L.latLngBounds(L.latLng(pos.latitude, pos.longitude), L.latLng(pos.latitude, pos.longitude));
      map.fitBounds(focusedMarkerBounds);
    }
  }, [focusedDevice])

  return (
    <></>
  );
};

export default GeofenceMapDeviceFocus;