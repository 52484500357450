import { FormProps } from '../../@types/Props';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useCommandContext } from '../../contexts/CommandContext';
import { useAuthContext } from '../../contexts/AuthenticationContext';
import { useEffect, useState, useTransition } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItemIcon, useMediaQuery, useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Command } from '../../@types/Command';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { usePermissionContext } from '../../contexts/PermissionContext';
import { useTranslation } from 'react-i18next';

const UserCommand = ({opened, close, title, subtitle}: FormProps) => {
  const selectedUserId = useSelector((state: RootState) => state.user.selectedUserId);
  const commands = useSelector((state: RootState) => state.command.items);
  const selectedCommands = useSelector((state: RootState) => state.command.selectedCommands);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { fetchCommandsByUserId } = useCommandContext();
  const { createPermission, deletePermission } = usePermissionContext();
  const { user } = useAuthContext();
  const { t } = useTranslation();
  
  const [CommandsSelected, setCommandsSelected] = useState<Command[]>([]);
  const [CommandsList, setCommandsList] = useState<Command[]>([])
  const [loading, setLoading] = useState(true);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onDialogClose = (event: object, reason: string) => {
    if (reason !== "backdropClick") close();
  };

  const onSaveButtonClicked = () => {
    CommandsList.forEach(n => {
      if (CommandChangedSelection(n) && CommandSelected(n)) {
        createPermission({
          userId: selectedUserId as number,
          commandId: n.id,
        }, onCancelButtonClicked);
      }
      else if (CommandChangedSelection(n) && !CommandSelected(n)) {
        deletePermission({
          userId: selectedUserId as number,
          commandId: n.id,
        }, onCancelButtonClicked);
      }
    });
  };

  const onCancelButtonClicked = () => {
    setLoading(true);
    setCommandsSelected([]);
    close();
  };

  const CommandWasSelected = (option: Command) => {
    if (selectedCommands !== null)
      return Object.entries(selectedCommands).map(x => x[1]).find(y => y.id === option.id) !== undefined;

    return false;
  }

  const CommandChangedSelection = (option: Command) => {
    return (CommandWasSelected(option) && !CommandSelected(option)) ||
      (!CommandWasSelected(option) && CommandSelected(option));
  }

  const CommandSelected = (option: Command) => {
    return CommandsSelected?.find(x => x.id === option.id) !== undefined;
  };

  const handleSelection = (option: Command) => {
    !CommandSelected(option) ?
      setCommandsSelected([...CommandsSelected, option]) : //add
      setCommandsSelected(CommandsSelected.filter(x => x.id !== option.id)); //remove
  };

  const orderCommandsList = () => {
    if (selectedCommands !== null) {
      setCommandsList(Object.entries(commands ?? []).map(x => x[1]).sort(
        (a, b) =>
          selectedCommands.find(y => y.id === a.id) !== undefined && selectedCommands.find(y => y.id === b.id) === undefined ? -1 :
          selectedCommands.find(y => y.id === a.id) === undefined && selectedCommands.find(y => y.id === b.id) !== undefined ? 1 :
          0
      ));
    }
  };

  useEffect(() => {
    if (user === null)
      return;
    
    if (Object.entries(commands).length === 0) fetchCommandsByUserId(user.id);
  });

  useEffect(() => {
    if (selectedCommands !== null) {
      setCommandsSelected(Object.entries(selectedCommands ?? []).map(x => x[1]));
      orderCommandsList();
      setLoading(false);
    }
  }, [selectedCommands]);

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen={fullScreen}
      open={opened}
      onClose={onDialogClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
        <DialogContent>
          <DialogContentText>
          {subtitle}
          </DialogContentText>
          {
            loading ? 
            Object.entries([1,2,3,4,5,6,7,8,9,10,11,12])
            .map((value, index) => <Skeleton key={index} containerClassName="flex flex-row h-12 w-full mb-2"/>)
            :
            <div>
              {
                CommandsList.map((value, index) => {
                  return (
                    <ListItem
                      key={index}
                      disablePadding
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <SettingsIcon />
                        </IconButton>
                      }
                    >
                      <ListItemButton
                        role={undefined}
                        onClick={() => handleSelection(value)}
                        
                      >
                        <ListItemIcon>
                        <Checkbox
                          key={index}
                          onClick={() => handleSelection(value)}
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={CommandSelected(value)}
                        />
                        </ListItemIcon>
                        <ListItemText id={`${value.id}`} primary={`${value.description} - ${value.id}`} />
                      </ListItemButton>
                    </ListItem>
                  )
                })
              }
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='error' onClick={onCancelButtonClicked}>
            CANCELAR
          </Button>
          <Button type='submit' onClick={onSaveButtonClicked} variant='contained' color='primary' autoFocus>
            SALVAR
          </Button>
        </DialogActions>
    </Dialog>
  );
};

export default UserCommand;