import L from 'leaflet';
import { useEffect, useState } from 'react';
import { LeafletTrackingMarker } from "react-leaflet-tracking-marker";
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useMap } from 'react-leaflet';
import RouteMapPopup from './RouteMapPopup';

type RouteMapMarkerType = {
  latitude: number;
  longitude: number;
  rotationAngle: number;
};

const getMinimum = (a: number, b: number) => Math.min(a, b);

const getMax = (a: number, b: number) => Math.max(a, b);

const RouteMapMarker = ({ latitude, longitude, rotationAngle}: RouteMapMarkerType) => {
  const map = useMap();
  const [prevPos, setPrevPos] = useState([latitude, longitude])
  
  const positions = useSelector((state: RootState) => state.report.positions);
  const positionsList = Object.entries(positions).map(x => x[1]);
  const latitudes = positionsList.map(p => p.latitude);
  const longitudes = positionsList.map(p => p.longitude);
  const minLatitude = latitudes.reduce(getMinimum, Infinity);
  const maxLatitude = latitudes.reduce(getMax, -Infinity);
  const minLongitude = longitudes.reduce(getMinimum, Infinity);
  const maxLongitude= longitudes.reduce(getMax, -Infinity);
  const bounds = L.latLngBounds(L.latLng(minLatitude, minLongitude), L.latLng(maxLatitude, maxLongitude));
  
  useEffect(() => {
    map.fitBounds(bounds);
  }, [positionsList]);

  useEffect(() => {
    if (prevPos[1] !== longitude && prevPos[0] !== latitude) setPrevPos([latitude, longitude])
  }, [latitude, longitude, prevPos]);

  return (
    <LeafletTrackingMarker
      icon={
        L.icon({
          // iconUrl: require(`../../assets/icons/markers/top/${(device.position.course % 360).toFixed(0)}.webp`),
          iconUrl: require(`../../assets/icons/markers/top/0.webp`),
          //iconSize: device.position.course.toFixed(0) === '0' ? [35, 35] : [45, 45],
          iconSize: [35,35],
          iconAnchor: [16.5,16.5],
          popupAnchor: [0,0],
          //iconAnchor: [22, 22],
          //popupAnchor: [0, -10],
        })}
      position={[latitude, longitude]}
      //previousPosition={[prevPos[0], prevPos[1]]}
      rotationAngle={(rotationAngle%360)/2}
      duration={1000}
      keepAtCenter={false}
    >
      <RouteMapPopup/>
    </LeafletTrackingMarker>
  )
}

export default RouteMapMarker;