import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, reportActions, trackActions } from '../../store';
import { useEffect, useState } from 'react';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Checkbox, FormControlLabel, FormGroup, InputLabelProps, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import Button from '../Common/Button';
import { useAuthContext } from '../../contexts/AuthenticationContext';
import { useDeviceContext } from '../../contexts/DeviceContext';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useReportContext } from '../../contexts/ReportContext';
import { dateToStringWithHour } from '../../util/util';

const SearchRoute = () => {
  const dispatch = useDispatch();
  const devices = useSelector((state: RootState) => state.device.items);
  const deviceCount = Object.entries(devices).length;
  const positions = useSelector((state: RootState) => state.report.positions);
  const positionsList = Object.entries(positions).map(x => x[1]);

  const { user } = useAuthContext();
  const { fetchDevicesAndPositions } = useDeviceContext();
  const { fetchPositionsByDeviceId } = useReportContext();
  const [beginDate, setBeginDate] = useState<Dayjs | null>(dayjs().subtract(3, 'hour'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [deviceId, setDeviceId] = useState<number | null | undefined>(null);

  
  const options = Object.entries(devices).map((value, index) => { 
    return {
      label: `${value[1].attributes.placa} - ${value[1].name} - (#${index})`,
      value: value[1].id
    }
  });
  
  useEffect(() => {
    if (user === null)
      return;
    
    if (deviceCount === 0)
      fetchDevicesAndPositions(user.id);
  });

  useEffect(() => {
    dispatch(trackActions.setTape(positionsList))
  }, [positionsList])

  return (
    <div className='m-4 h-auto w-80 select-none'>

        <Stack spacing={3}>
          <DesktopDateTimePicker
            label='Selecione o dia e hora iniciais'
            format='DD/MM/YYYY HH:mm'
            ampm={false}
            value={beginDate}
            onChange={value => setBeginDate(value)}
          />
        </Stack>

      <div className='pt-4'></div>
        <Stack spacing={3}>
          <DesktopDateTimePicker
            label='Selecione o dia e hora finais'
            format='DD/MM/YYYY HH:mm'
            ampm={false}
            value={endDate}
            onChange={value => setEndDate(value)}
          />
        </Stack>

      {
        deviceCount === 0 ?
        <Skeleton containerClassName="flex h-16 w-full"/> :
        <Autocomplete
          // className={Object.entries(groups).length > 1 ? 'w-80' : 'hidden'}
          className='pt-4 pb-4'
          autoComplete
          includeInputInList
          id="placa-autocomplete"
          isOptionEqualToValue={(option, value) => option.value === value.value && option.label === value.label}
          options={options}
          onChange={(event, item) => setDeviceId(item !== undefined ? item?.value : null)}
          renderInput={(params) => (
            <TextField {...(params as AutocompleteRenderInputParams & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })} label="Selecione a placa" variant="outlined" />
          )}
        />
      }
      {/* <FormGroup className='py-2'>
        <FormControlLabel
          disabled={cursor !== 0}
          control={
            <Checkbox
              value={followDevice}
              onChange={(e) => dispatch(trackActions.toggleFollowDevice())}
            />
          }
          label="Acompanhar veículo"
        />
      </FormGroup> */}

      <Button
        action={() => fetchPositionsByDeviceId(deviceId as number, dateToStringWithHour(beginDate?.toDate()), dateToStringWithHour(endDate?.toDate()))}
        label='Buscar'
      />
      
    </div>
  );
};

export default SearchRoute;