import { Alert, Snackbar, TextField } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { api } from '../../api/api';
import { useAuthContext } from '../../contexts/AuthenticationContext';
import { sessionActions } from '../../store';
import Button from '../Common/Button';

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { user, logIn } = useAuthContext();

  const handleLoginTraccar = (email: string, password: string) => {
    api.session.logInTraccar(email, password)
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      else if (res.status === 401) {
        setErrorMessage('Login ou senha incorretos!');
        setShowMessage(true);
        return null;
      }
      else if (res.status === 400) {
        setErrorMessage('Sem autorização para acesso.');
        setShowMessage(true);
        return null;
      } 
      else {
        setErrorMessage("Não foi possível completar a requisição. Tente novamente.");
        setShowMessage(true);
        return null;
      }
    })
    .then(usr => {
      if (usr !== null) {
        handleLogIn(email, password);
        //logIn(usr);
        //dispatch(sessionActions.updateUser(usr));
      }
    })
  }

  const handleLogIn = (email: string, password: string) => {
    api.session.logIn(email, password)
    .then((response) => {
      if (response.success) {
        logIn(response.content);
        dispatch(sessionActions.updateUser(response.content));
      }
      else {
        setErrorMessage(response.errors[0]);
        setShowMessage(true);
      }
    })
  }

  if (user !== null)
    return <Navigate to={'/map'} />
  
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showMessage}
        autoHideDuration={4000}
        onClose={() => setShowMessage(false)}
      >
        <Alert severity="info">{errorMessage}</Alert>
      </Snackbar>
      <div className='w-screen h-screen flex flex-row justify-center bg-default'>
        <div className='w-96 px-6 self-center bg-white rounded-lg'>
          <img
            src={require('../../assets/frottus.png')}
            alt='logo'
            className='w-40 rounded-3xl m-auto mb-10 mt-10 block'
          />
          <TextField
            size='small'
            fullWidth
            margin="dense"
            id="login"
            label="Login"
            variant="filled"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            size='small'
            fullWidth
            margin="dense"
            id="password"
            label="Senha"
            variant="filled"
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleLoginTraccar(email, password)
              }
            }}
          />
          <div className='pt-4 mb-10'>
          <Button
            action={() => handleLoginTraccar(email, password)}
            label='Entrar'
          />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;