import { createContext, useContext, useEffect } from "react";
import { TranslationContextType, ChildrenProps } from "../@types/Props";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "../hooks/useLocalStorage";

const TranslationContext = createContext<TranslationContextType | null>(null);

export const TranslationProvider = ({children} : ChildrenProps) => {
  const [language, setLanguage] = useLocalStorage('language', null);
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  }

  const getLanguage = () => {
    return language;
  }

  useEffect(() => {
    const lng = language ?? "pt_BR";
    changeLanguage(lng);
  }, []);

  return (
    <TranslationContext.Provider
      value={{
        changeLanguage,
        getLanguage,
      }}
    >
      {children}
    </TranslationContext.Provider>
    );
}

export const useTranslationContext = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error(
      'useTranslationContext must be used within a TranslationProvider'
    );
  }
  const {
    changeLanguage,
    getLanguage,
  } = context;
  return {
    changeLanguage,
    getLanguage,
  };
}