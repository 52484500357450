const getHeaders = () => {
  const token = window.localStorage.getItem('token');
  return {
    'Content-Type': 'application/json',
    'Authorization': `Basic ${token}`
  };
}

export const getOptions = () => {
  return {
    method: 'GET',
    headers: getHeaders(),
  };
}

export const deleteOptions = () => {
  return {
    method: 'DELETE',
    headers: getHeaders(),
  };
}

export const deleteOptionsWithBody = (body: string) => {
  return {
    method: 'DELETE',
    body: body,
    headers: getHeaders(),
  };
}

export const putOptions = (body: string) => {
  return {
    method: 'PUT',
    body: body,
    headers: getHeaders(),
  };
}

export const postOptions = (body: string) => {
  return {
    method: 'POST',
    body: body,
    headers: getHeaders(),
  };
}