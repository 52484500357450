import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files
import enNs from "./locales/en/ns1.json";
import ptBRNs from "./locales/pt-BR/ns1.json";

export const defaultNS = 'ns1';

i18next.use(initReactI18next).init({
  //debug: true,
  //fallbackLng: 'en',
  lng: "pt_BR",
  ns: ["ns1"],
  defaultNS,
  resources: {
    en: {
      ns1: enNs,
    },
    pt_BR: {
      ns1: ptBRNs,
    },
  },
});