import { Device } from '../../@types/Device';
import 'leaflet-rotatedmarker';
import RotatedMarker from '../Common/RotatedMarker';
import GeofenceMapPopup from './GeofenceMapPopup';
import { getIcon } from '../../util/util';

type GeofenceMapMarkerProps = {
  device: Device;
};

const GeofenceMapMarker = ({device} : GeofenceMapMarkerProps) => {
  const pos = device.position;

  return (
    pos ?
    <RotatedMarker
      position={[pos.latitude, pos.longitude]}
      icon={getIcon(device.category)}
      rotationAngle={pos.course}
      rotationOrigin={"center"}
      deviceId={device.id}
    >
      <GeofenceMapPopup device={device}/>
    </RotatedMarker>
    : null
  )
};

export default GeofenceMapMarker;