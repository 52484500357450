import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { MaterialReactTable, MRT_SortingState, MRT_Virtualizer } from 'material-react-table';
import type { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'; // If using TypeScript (optional, but recommended)
import { TotalGroupDevices, TotalGroupDevicesTableProps } from '../../@types/Props';
import TableTopToolbarAction from '../Common/TableTopToolbarAction';
import TableContainer from '../Common/TableContainer';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { mkConfig, generateCsv, download } from 'export-to-csv';

const TotalGroupDevicesTable = memo(({totalGroupDevices, actions}: TotalGroupDevicesTableProps) => {
  const [data, setData] = useState<TotalGroupDevices[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'groupName', desc: false, }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  const columns = useMemo<MRT_ColumnDef<TotalGroupDevices>[]>(
    () => [
      {
        accessorKey: 'groupName', //simple recommended way to define a column
        header: 'Nome',
        size: 55,
      },
      {
        accessorKey: 'totalDevices', //id required if you use accessorFn instead of accessorKey
        header: 'Total',
        size: 50,
      },
    ],
    [],
  );

  const handleExportCsvRows = (table: MRT_TableInstance<TotalGroupDevices>) => {
    let rows = table.getFilteredRowModel().rows
    let columnHeaders = new Array<string>();
    rows[0].getAllCells().forEach(cel => {
      if (cel.column.getIsVisible() && cel.column.columnDef.header !== "Actions")
        columnHeaders.push(cel.column.columnDef.header as string)
    })

    const rowData = rows.map((row) => {
      return {
        Nome: table.getColumn(columns[0].accessorKey as string).getIsVisible() ? row.original.groupName : null,
        Total: table.getColumn(columns[1].accessorKey as string).getIsVisible() ? row.original.totalDevices : null,
      }
    });
    const csvConfig = mkConfig({
      fieldSeparator: ';',
      decimalSeparator: '.',
      useKeysAsHeaders: false,
      filename: 'Total de veículos por empresa',
      columnHeaders: columnHeaders
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportPdfRows = (table: MRT_TableInstance<TotalGroupDevices>) => {
    const doc = new jsPDF();
    let rows = table.getFilteredRowModel().rows;
    const tableHeaders = columns.map((c) => table.getColumn(c.accessorKey as string).getIsVisible() ? c.header : null);
    const tableData = rows.map((row) => {
      return [
        table.getColumn(columns[0].accessorKey as string).getIsVisible() ? row.original.groupName : null,
        table.getColumn(columns[1].accessorKey as string).getIsVisible() ? row.original.totalDevices : null,
      ]
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save('Total de veículos por empresa.pdf');
  };

  //optionally access the underlying virtualizer instance
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setData(totalGroupDevices);
      setIsLoading(false);
    }
  }, [totalGroupDevices]);

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  return (
    <TableContainer>
      <MaterialReactTable
        defaultColumn={{
          maxSize: 400,
          minSize: 20,
          size: 120, //default size is usually 180
        }}
        columns={columns}
        data={data}
        //enableColumnOrdering={false}
        enableColumnDragging={false}
        enableGlobalFilter
        enablePagination
        positionPagination='bottom'
        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
        //state={{ pagination }} //pass the pagination state to the table
        initialState={{ density: 'comfortable' }}
        enableDensityToggle={false}

        //vitualization props
        muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 11.5rem)' } }}
        enableRowVirtualization
        onSortingChange={setSorting}
        state={{ pagination, isLoading, sorting }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
        rowVirtualizerOptions={{ overscan: 5 }} //optionally customize the row virtualizer
        columnVirtualizerOptions={{ overscan: 2 }} //optionally customize the column virtualizer

        renderTopToolbarCustomActions={({ table }) => 
          <TableTopToolbarAction
            addAction={undefined}
            exportPdfAction={table.getPrePaginationRowModel().rows.length !== 0 ?
              () => handleExportPdfRows(table) : undefined}
            exportCsvAction={table.getPrePaginationRowModel().rows.length !== 0 ?
              () => handleExportCsvRows(table) : undefined}
          />
        }

        editDisplayMode='modal' //default
        enableColumnOrdering
      />
    </TableContainer>
  );
});

export default TotalGroupDevicesTable;