import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Device } from '../@types/Device';
import { Position } from '../@types/Position';

type FilterDevice = {
  groupId: number;
  searchTerm: string;
  ignitionFilter: null | boolean;
}

export interface DevicesState {
  items: { [id: number] : Device };
  selectedId: null | number;
  selectedDevices: null | Device[];
  filteredDevices: null | Device[];
  focusedDevice: null | Device;
  ignitionFilter: null | boolean;
}

const initialState: DevicesState = {
  items: {},
  selectedId: null,
  selectedDevices: null,
  filteredDevices: null,
  focusedDevice: null,
  ignitionFilter: null,
}

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    refresh: (state, action: PayloadAction<Device[]>) => {
      state.items = {};
      action.payload.forEach((item: Device) => state.items[item.id] = item);
    },
    update: (state, action: PayloadAction<Device[]>) => {
      action.payload.forEach((device: Device) => {
        if (device !== null && device !== undefined && state.items[device.id] !== null && state.items[device.id] !== undefined) {
          state.items[device.id].status = device.status;
          state.items[device.id].lastUpdate = device.lastUpdate;
          state.items[device.id].positionId = device.positionId;

          state.filteredDevices?.forEach(d => {
            if (d.id === device.id) {
              d.status = device.status;
              d.lastUpdate = device.lastUpdate;
              d.positionId = device.positionId;
            }
          })
        }
      });
    },
    focusDevice: (state, action: PayloadAction<Device>) => {
      state.focusedDevice = action.payload;
    },
    unfocusDevice: (state) => {
      state.focusedDevice = null;
    },
    select: (state, action: PayloadAction<number>) => {
      state.selectedId = action.payload;
    },
    unSelect: (state) => {
      state.selectedId = null;
    },
    remove: (state, action: PayloadAction<number>) => {
      delete state.items[action.payload];
    },
    reset: (state) => {
      state.items = {};
      state.selectedId = null;
      state.selectedDevices = null;
      state.filteredDevices = null;
      state.focusedDevice = null;
      state.ignitionFilter = null;
    },
    updatePositions: (state, action: PayloadAction<Position[]>) => {
      action.payload.forEach((position: Position) => {
        if (position !== null && position !== undefined && state.items[position.deviceId] !== null && state.items[position.deviceId] !== undefined)
          state.items[position.deviceId].position = position;
        
        state.filteredDevices?.forEach(device => {
          if (device !== null && device !== undefined && device.id === position.deviceId) {
            device.position = position;
          }
        })
        
        if (state.focusedDevice !== null && position.deviceId === state.focusedDevice.id) {
          state.focusedDevice.position = position;
        }
      });

    },
    setSelectedDevices: (state, action: PayloadAction<Device[] | null>) => {
      state.selectedDevices = action.payload;
    },
    setIgnitionFilter: (state, action: PayloadAction<boolean | null>) => {
      state.ignitionFilter = action.payload;
    },
    filterDevices: (state, action: PayloadAction<FilterDevice>) => {
      if (action.payload.groupId !== 0) {
        state.filteredDevices = Object.entries(state.items)
        .map(x => x[1])
        //.filter(x => action.payload.ignitionFilter === null || !!x.position?.attributes.ignition === action.payload.ignitionFilter)
        .filter(x => x.groupId === action.payload.groupId)
        .filter(x => `${x.name} ${x.attributes.placa}`.toUpperCase().includes(action.payload.searchTerm.toUpperCase()));
      } else if (action.payload.groupId === 0 && action.payload.searchTerm.length > 0) {
        state.filteredDevices = Object.entries(state.items)
        .map(x => x[1])
        //.filter(x => action.payload.ignitionFilter === null || !!x.position?.attributes.ignition === action.payload.ignitionFilter)
        .filter(x => `${x.name} ${x.attributes.placa}`.toUpperCase().includes(action.payload.searchTerm.toUpperCase()));
      } else if (action.payload.groupId === 0 && action.payload.searchTerm.length === 0 && action.payload.ignitionFilter === null) {
        state.filteredDevices = null;
      } else if (action.payload.groupId === 0 && action.payload.searchTerm.length === 0 && action.payload.ignitionFilter !== null) {
        state.filteredDevices = Object.entries(state.items)
        .map(x => x[1])
        //.filter(x => !!x.position?.attributes.ignition === action.payload.ignitionFilter);
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const deviceActions = deviceSlice.actions

export const deviceReducer = deviceSlice.reducer