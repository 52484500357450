import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { deviceActions, RootState } from '../../store';
import { InputLabelProps } from '@mui/material';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useAuthContext } from '../../contexts/AuthenticationContext';

const SearchDevice = () => {
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const groups = useSelector((state: RootState) => state.group.items);
  const ignitionFilter = useSelector((state: RootState) => state.device.ignitionFilter);
  const groupsCount = Object.entries(groups).length
  const [searchTerm, setSearchTerm] = useState('');
  const [groupId, setGroupId] = useState(0);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(deviceActions.filterDevices({
        groupId,
        searchTerm,
        ignitionFilter,
      }));
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm, groupId, ignitionFilter])
  
  return (
    <div className={Object.entries(groups).length > 1 ? 'm-4 h-auto' : 'm-4 h-auto w-80'}>
      {
        !!user && !user.isClient ? (
        groupsCount === 0 ?
          Object.entries([1])
            .map((value, index) => <Skeleton key={index} containerClassName="flex flex-row h-16 w-full mb-2"/>) :
          <Autocomplete
            className={groupsCount > 1 ? 'w-80' : 'hidden'}
            autoComplete
            includeInputInList
            id="group-autocomplete"
            isOptionEqualToValue={(option, value) => option.label === value.label && option.value === value.value}
            options={Object.entries(groups).map((value, index) => { 
              return {
                label: value[1].name,
                value: value[1].id
              }
            })}
            onChange={(event, item) =>
              item?.value !== undefined ?
              setGroupId(item.value) :
              setGroupId(0)
            }
            renderInput={(params) => (
              <TextField {...(params as AutocompleteRenderInputParams & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })} label="Grupo" variant="outlined" />
            )}
          />
        ) : null
      }
      <TextField
        fullWidth
        margin="dense"
        id="search-device"
        label="Buscar veículo"
        variant="outlined"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  );
};

export default SearchDevice;