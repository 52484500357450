
import { useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import L from 'leaflet';
import { useEffect } from 'react';

const MainMapDeviceFocus = () => {
  const focusedDevice = useSelector((state: RootState) => state.device.focusedDevice)
  const map = useMap();

  useEffect(() => {
    if (focusedDevice !== null && focusedDevice.position !== null && focusedDevice.position !== undefined) {
      const pos = focusedDevice.position;
      const focusedMarkerBounds = L.latLngBounds(L.latLng(pos.latitude, pos.longitude), L.latLng(pos.latitude, pos.longitude));
      map.fitBounds(focusedMarkerBounds);
    }
  }, [focusedDevice])

  useEffect(() => {
    var satellite = L.tileLayer(`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`),
    streets   = L.tileLayer(`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`);
    //streets   = L.tileLayer(`https://https://{s}.tile.openstreetmap.org/{z}/{x}/{y}{r}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`);
  
    var baseMaps = {
      "Satélite": satellite,
      "Mapa": streets,
      //"Tráfego": traffic,
    };
  
    //map.addLayer(satellite);
    map.addLayer(streets);
    L.control.layers(baseMaps).addTo(map);
  }, [])

  return (
    <></>
  );
};

export default MainMapDeviceFocus;