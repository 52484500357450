import MainContentContainer from '../Common/MainContentContainer';
import Header from '../Common/Header';
import NotificationSidebar from './NotificationSidebar';
import NotificationTable from './NotificationTable';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { RootState, notificationActions } from '../../store';
import { TableActionType, NotificationFormValue } from '../../@types/Props';
import NotificationForm from './NotificationForm';
import AlertDialog from '../Common/AlertDialog';
import { useNotificationContext } from '../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import { Notification } from '../../@types/Notification';
import { TestNotification } from '../../@types/Responses';

const NotificationScreen = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state: RootState) => state.notification.items);
  const selectedNotificationId = useSelector((state: RootState) => state.notification.selectedNotificationId);
  const {
    generateUpdateNotification,
    createNotification,
    deleteNotificationById,
    updateNotificationById,
    fetchNotifications,
    sendWebNotificationTest,
    sendFirebaseNotificationTest,
  } = useNotificationContext();
  const { t } = useTranslation();

  const [showNotificationAdd, setShowNotificationAdd] = useState(false);
  const [showNotificationEdit, setShowNotificationEdit] = useState(false);
  const [showNotificationData, setShowNotificationData] = useState(false);
  const [showNotificationDelete, setShowNotificationDelete] = useState(false);

  const toggleShowNotificationAdd = () => setShowNotificationAdd(!showNotificationAdd);
  const toggleShowNotificationEdit = () => setShowNotificationEdit(!showNotificationEdit);
  const toggleShowNotificationData = () => setShowNotificationData(!showNotificationData);
  const toggleShowNotificationDelete = () => setShowNotificationDelete(!showNotificationDelete);

  const updateNotifications = {
    action: () => fetchNotifications(),
  };

  const addNotification = {
    action: () => toggleShowNotificationAdd(),
    onSubmit: (values: NotificationFormValue) => {
      createNotification(values, addNotification.close);
    },
    close: () => {
      toggleShowNotificationAdd();
    },
  };

  const deleteNotification = {
    action: (item: TableActionType) => {
      dispatch(notificationActions.select((item as Notification).id))
      toggleShowNotificationDelete()
    },
    close: () => {
      dispatch(notificationActions.unSelect());
      toggleShowNotificationDelete();
    },
    onAgree: () => {
      const selectedNotification = selectedNotificationId !== null ? notifications[selectedNotificationId] : undefined;
      if (selectedNotification)
        deleteNotificationById(selectedNotification.id, deleteNotification.close);
    }
  };

  const editNotification = {
    action: (item: TableActionType) => {
      dispatch(notificationActions.select((item as Notification).id))
      toggleShowNotificationEdit();
    },
    onSubmit: (values: NotificationFormValue) => {
      const selectedNotification = selectedNotificationId !== null ? notifications[selectedNotificationId] : undefined;
      const newNotification = generateUpdateNotification(selectedNotification as Notification, values);
      updateNotificationById(newNotification, editNotification.close);
    },
    close: () => {
      dispatch(notificationActions.unSelect());
      toggleShowNotificationEdit();
    },
  };

  const showNotification = {
    action: (item: TableActionType) => {
      dispatch(notificationActions.select((item as Notification).id))
      toggleShowNotificationData();
    },
    onSubmit: (values: NotificationFormValue) => {
      dispatch(notificationActions.unSelect());
      toggleShowNotificationData();
    },
    close: () => {
      dispatch(notificationActions.unSelect());
      toggleShowNotificationData();
    },
  };

  const testNotification = {
    action: (item: TableActionType) => {
      console.log(item)
      if ((item as TestNotification).notificators.includes("web"))
        sendWebNotificationTest(item as TestNotification);
      if ((item as TestNotification).notificators.includes("firebase"))
        sendFirebaseNotificationTest(item as TestNotification);
    },
  };

  useEffect(() => {
    if (Object.entries(notifications).length === 0) fetchNotifications();
  }, []);

  return (
    <div className='overflow-hidden'>
      <Header title={`${t('tracking')} > ${t('notifications')}`}/>

      <MainContentContainer>

        <NotificationSidebar/>

        <NotificationTable
          notifications={notifications ? Object.entries(notifications).map((value, index) => value[1]) : []}
          actions={{
            showDataAction: showNotification.action,
            updateAction: updateNotifications.action,
            addAction: addNotification.action,
            deleteAction: deleteNotification.action,
            editAction: editNotification.action,
            //commandAction: commandUser.action,
            // notificationAction: notificationUser.action,
            //vehicleAction: deviceUser.action,
            //clientAction: user.isAdmin ? clientUser.action : undefined,
            //billAction: billUser.action,
            testNotificationAction: testNotification.action,
          }}
        />

        <NotificationForm
          title='Adicionar notificação'
          subtitle='Insira as informações da notificação e clique em SALVAR para confirmar ou clique em CANCELAR para descartar.'
          onSubmit={addNotification.onSubmit}
          notification={undefined}
          opened={showNotificationAdd}
          readonly={false}
          close={addNotification.close}
        />

        <NotificationForm
          title='Visualizar notificação'
          subtitle='Visualize as informações da notificação e clique em FECHAR para sair da tela.'
          onSubmit={showNotification.onSubmit}
          notification={selectedNotificationId !== null ? notifications[selectedNotificationId] : undefined}
          opened={showNotificationData}
          readonly={true}
          close={showNotification.close}
        />

        <NotificationForm
          title='Editar notificação'
          subtitle='Edite as informações da notificação e clique em ATUALIZAR para confirmar ou clique em CANCELAR para descartar'
          onSubmit={editNotification.onSubmit}
          notification={selectedNotificationId !== null ? notifications[selectedNotificationId] : undefined}
          opened={showNotificationEdit}
          readonly={false}
          close={editNotification.close}
        />

        <AlertDialog
          title='Remover notificação'
          text='Deseja remover a notificação? Essa ação é irreversível.'
          opened={showNotificationDelete}
          onAgree={deleteNotification.onAgree}
          onDisagree={deleteNotification.close}
          onClose={deleteNotification.close}
        />

      </MainContentContainer>
    </div>
  );
};

export default NotificationScreen;