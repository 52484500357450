import { FormProps } from '../../@types/Props';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useCommandContext } from '../../contexts/CommandContext';
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField, useMediaQuery, useTheme } from '@mui/material';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from 'react-i18next';

const SendCommand = ({opened, close, title, subtitle}: FormProps) => {
  const selectedDeviceId = useSelector((state: RootState) => state.device.selectedId);
  const selectedCommands = useSelector((state: RootState) => state.command.selectedCommands);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { sendCommand } = useCommandContext();
  const { t } = useTranslation();
  
  const [loading, setLoading] = useState(true);
  const [selectedCommandId, setSelectedCommandId] = useState<number>(0);

  const onDialogClose = (event: object, reason: string) => {
    if (reason !== "backdropClick") close();
  };

  const onSaveButtonClicked = () => {
    if (selectedDeviceId !== null)
      sendCommand(selectedDeviceId, selectedCommandId, close);
  };

  const onCancelButtonClicked = () => {
    setLoading(true);
    close();
  };

  useEffect(() => {
    if (selectedCommands !== null) {
      setLoading(false);
    }
  }, [selectedCommands]);

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen={fullScreen}
      open={opened}
      onClose={onDialogClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
        <DialogContent>
          <DialogContentText>
          {subtitle}
          </DialogContentText>
          {
            loading ? 
            Object.entries([1])
            .map((value, index) => <Skeleton key={index} containerClassName="flex flex-row h-12 w-52 mb-2"/>)
            :
            <div className='flex flex-col py-4'>
              <div className='flex flex-col md:flex-row'>
                <TextField
                  select
                  className='w-full md:w-1/3'
                  size='small'
                  variant="filled"
                  label="Comando"
                  id="command"
                  name="command"
                  value={selectedCommandId === 0 ? '' : selectedCommandId}
                  onChange={(e) => setSelectedCommandId(parseInt(e.target.value))}
                >
                  {
                    selectedCommands?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.description}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='error' onClick={onCancelButtonClicked}>
            CANCELAR
          </Button>
          <Button type='submit' onClick={onSaveButtonClicked} variant='contained' color='primary' autoFocus>
            ENVIAR
          </Button>
        </DialogActions>
    </Dialog>
  );
};

export default SendCommand;