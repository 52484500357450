import { Outlet } from 'react-router-dom';

const MapLayout = () => {
  return (
    <div className='flex-auto flex-column bg-white'>
      <Outlet/>
    </div>
  );
};

export default MapLayout;