import MainContentContainer from '../Common/MainContentContainer';
import Header from '../Common/Header';
import ReportSidebar from './ReportSidebar';
import ReportTable from './ReportTable';
import { useTranslation } from 'react-i18next';

const Report = () => {
  const { t } = useTranslation();

  return (
    <div className='overflow-hidden'>

      <Header title={`${t('tracking')} > ${t('reports')}`}/>

      <MainContentContainer>

        <ReportSidebar/>
        
        <ReportTable/>

      </MainContentContainer>
      
    </div>
  );
};

export default Report;