import { MapContainer, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import DeviceMarker from './DeviceMarker';
import MainMapDeviceFocus from './MainMapDeviceFocus';

const DeviceMap = () => {
  const devices = useSelector((state: RootState) => state.device.items)
  
  return (
    <div className='w-full'>
      <MapContainer
        center={[-20.2821076,-40.3207233]}
        zoom={10}
        scrollWheelZoom={true}
        className='w-full h-full'
      >
        {
          Object.entries(devices).map((value, index) => <DeviceMarker key={index} device={value[1]}/>)
        }

        <MainMapDeviceFocus/>
      </MapContainer>
    </div>
  );
};

export default DeviceMap;