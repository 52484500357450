import { createContext, useContext } from "react";
import { useDispatch } from "react-redux";
import { CommandContextType, ChildrenProps } from "../@types/Props";
import { commandActions } from "../store";
import { api } from "../api/api";
import { Command } from "../@types/Command";
import { useSnackbarContext } from "./SnackbarContext";
import { ResponseContentType, SendCommand } from "../@types/Responses";

const CommandContext = createContext<CommandContextType | null>(null);

export const CommandProvider = ({children} : ChildrenProps) => {
  const dispatch = useDispatch();

  const {
    sendRequest,
  } = useSnackbarContext();

  const fetchCommandsByUserId = (userId: number) => {
    const request = async () => await api.command.fetchCommandsByUserId(userId);
    const onSuccess = (content: ResponseContentType) => dispatch(commandActions.refresh(content as Command[]));
    sendRequest(request, onSuccess, "");
  }

  const fetchCommandsSelectedUser = (userId: number) => {
    const request = async () => await api.command.fetchCommandsByUserId(userId);
    const onSuccess = (content: ResponseContentType) => dispatch(commandActions.setSelectedCommands(content as Command[]));
    sendRequest(request, onSuccess, "");
  };

  const fetchCommandsSelectedDevice = (deviceId: number) => {
    const request = async () => await api.command.fetchCommandsByDeviceId(deviceId);
    const onSuccess = (content: ResponseContentType) => dispatch(commandActions.setSelectedCommands(content as Command[]));
    sendRequest(request, onSuccess, "");
  };

  const sendCommand = async (deviceId: number, commandId: number, callback?: () => void) => {
    const sendCommand = {
      id: commandId,
      deviceId: deviceId,
    } as SendCommand;
    const request = async () => await api.command.sendCommand(sendCommand);
    const onSuccess = (content: ResponseContentType) => {
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Comando enviado com sucesso.");
  }

  return (
    <CommandContext.Provider
      value={{
        fetchCommandsByUserId,
        fetchCommandsSelectedUser,
        fetchCommandsSelectedDevice,
        sendCommand,
      }}
    >
      {children}
    </CommandContext.Provider>
    );
}

export const useCommandContext = () => {
  const context = useContext(CommandContext);
  if (!context) {
    throw new Error(
      'useCommandContext must be used within a CommandProvider'
    );
  }
  const {
    fetchCommandsByUserId,
    fetchCommandsSelectedUser,
    fetchCommandsSelectedDevice,
    sendCommand,
  } = context;
  return {
    fetchCommandsByUserId,
    fetchCommandsSelectedUser,
    fetchCommandsSelectedDevice,
    sendCommand,
  };
}