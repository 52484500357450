import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { MaterialReactTable, MRT_SortingState, MRT_Virtualizer } from 'material-react-table';
import type { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'; // If using TypeScript (optional, but recommended)
import { Notification } from '../../@types/Notification';
import { EventValueType, NotificationTableProps } from '../../@types/Props';
import TableTopToolbarAction from '../Common/TableTopToolbarAction';
import RowActions from '../Common/RowActions';
import TableContainer from '../Common/TableContainer';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useTranslation } from 'react-i18next';

const NotificationTable = memo(({notifications, actions}: NotificationTableProps) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  const columns = useMemo<MRT_ColumnDef<Notification>[]>(
    () => [
      {
        accessorKey: 'type', //simple recommended way to define a column
        accessorFn: (row) => t(row.type as EventValueType),
        header: 'Tipo',
        size: 50,
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'always',
        accessorFn: (row) => row.always ? 'Sim' : 'Não',
        header: 'Todos veículos',
        size: 50,
      },
      {
        accessorKey: 'notificators',
        header: 'Canais',
        size: 50,
      },
    //   {
    //     accessorKey: 'disabled',
    //     accessorFn: (row) => row.disabled ? 'Inativo' : 'Ativo',
    //     header: 'Situação',
    //     size: 50,
    //   },
    ],
    [],
  );

  //optionally access the underlying virtualizer instance
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);
  
  const [data, setData] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'type', desc: false, }]);

  const handleExportCsvRows = (table: MRT_TableInstance<Notification>) => {
    let rows = table.getFilteredRowModel().rows
    let columnHeaders = new Array<string>();
    rows[0].getAllCells().forEach(cel => {
      if (cel.column.getIsVisible() && cel.column.columnDef.header !== "Actions")
        columnHeaders.push(cel.column.columnDef.header as string)
    })

    const rowData = rows.map((row) => {
      return {
        Tipo: table.getColumn(columns[0].accessorKey as string).getIsVisible() ? t(row.original.type as EventValueType) : null,
        "Todos os veículos": table.getColumn(columns[1].accessorKey as string).getIsVisible() ? (row.original.always ? "Sim" : "Não") : null,
        Canais: table.getColumn(columns[2].accessorKey as string).getIsVisible() ? row.original.notificators : null,
      }
    });
    const csvConfig = mkConfig({
      fieldSeparator: ';',
      decimalSeparator: '.',
      useKeysAsHeaders: false,
      filename: 'Notificações',
      columnHeaders: columnHeaders
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (table: MRT_TableInstance<Notification>) => {
    const doc = new jsPDF();
    let rows = table.getFilteredRowModel().rows;
    const tableHeaders = columns.map((c) => table.getColumn(c.accessorKey as string).getIsVisible() ? c.header : null);
    const tableData = rows.map((row) => {
      return [
        table.getColumn(columns[0].accessorKey as string).getIsVisible() ? t(row.original.type as EventValueType) : null,
        table.getColumn(columns[1].accessorKey as string).getIsVisible() ? (row.original.always ? "Sim" : "Não") : null,
        table.getColumn(columns[2].accessorKey as string).getIsVisible() ? row.original.notificators : null,
      ]
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save('Notificações.pdf');
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setData(notifications);
      setIsLoading(false);
    }
  }, [notifications]);

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);
    
  return (
    <TableContainer>
      <MaterialReactTable
        defaultColumn={{
          maxSize: 400,
          minSize: 20,
          size: 120, //default size is usually 180
        }}
        columns={columns}
        data={data}
        //enableColumnOrdering={false}
        enableColumnDragging={false}
        enableGlobalFilter
        enablePagination
        positionPagination='bottom'
        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
        //state={{ pagination }} //pass the pagination state to the table
        initialState={{ density: 'comfortable' }}
        enableDensityToggle={false}

        //vitualization props
        muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 11.5rem)' } }}
        enableRowVirtualization
        onSortingChange={setSorting}
        state={{ pagination, isLoading, sorting }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
        rowVirtualizerOptions={{ overscan: 5 }} //optionally customize the row virtualizer
        columnVirtualizerOptions={{ overscan: 2 }} //optionally customize the column virtualizer

        renderTopToolbarCustomActions={({ table }) => 
          <TableTopToolbarAction
            updateAction={actions.updateAction}
            addAction={actions.addAction}
            exportPdfAction={table.getPrePaginationRowModel().rows.length !== 0 ?
              () => handleExportRows(table) : undefined}
            exportCsvAction={table.getPrePaginationRowModel().rows.length !== 0 ?
              () => handleExportCsvRows(table) : undefined}
          />
        }

        editDisplayMode='modal' //default
        enableColumnOrdering
        enableEditing
        //onEditingRowSave={handleSaveRowEdits}
        //onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => 
          <RowActions
            showDataAction={() => {
              if (actions.showDataAction !== undefined) actions.showDataAction(row.original as Notification);
            }}
            deleteAction={() => {
              if (actions.deleteAction !== undefined) actions.deleteAction(row.original as Notification);
            }}
            editAction={() => {
              if (actions.editAction !== undefined) actions.editAction(row.original as Notification);
            }}
            testNotificationAction={() => {
              if (actions.testNotificationAction !== undefined) actions.testNotificationAction(row.original as Notification);
            }}
          />
        }
      />
    </TableContainer>
  );
});

export default NotificationTable;