import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { UserFormProps } from '../../@types/Props';
import { useFormik } from "formik";
import * as yup from "yup";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useEffect, useState } from 'react';

const UserForm = ({title, subtitle, user, opened, readonly, close, onSubmit}: UserFormProps) => {
  const emptyValues = {
    name: "",
    dateBirth: "",
    login: "",
    email: "",
    phone: "",
    extraPhone: "",
    password: "",
    active: true,
    notificationTokens: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: yup.object({
      name: yup.string().required("Campo obrigatório."),
      dateBirth: yup.date().notRequired(),
      login: yup.string().required("Campo obrigatório."),
      email: yup.string().email("E-mail inválido.").notRequired(),
      phone: yup.string().notRequired(),
      extraPhone: yup.string().notRequired(),
      notificationTokens: yup.string().notRequired(),
      password: user ? yup.string() : yup.string().required("Campo obrigatório")
    }),
    onSubmit: onSubmit,
  });

  const onDialogClose = (event: object, reason: string) => {
    if (reason !== "backdropClick") close();
  }

  const onCancelButtonClicked = () => {
    formik.resetForm({ values: emptyValues })
    formik.setErrors({});
    close();
  }

  useEffect(() => {
    if (user !== null && user !== undefined) {
      const dateSplit = user?.attributes?.dateBirth?.split('/');
      const currentDateBirth = dateSplit && dateSplit.length === 3 ? `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}` : '';

      formik.setValues({
        name: user.name ?? '',
        dateBirth: currentDateBirth,
        login: user.email ?? '',
        email: user.attributes.email ?? '',
        phone: user.phone ?? '',
        extraPhone: user.attributes.celular ?? '',
        password: user.password ?? '',
        active: !user.disabled,
        notificationTokens: user.attributes?.notificationTokens
      })
    }
  }, [user]);

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen={fullScreen}
      open={opened}
      onClose={onDialogClose}
      aria-labelledby="responsive-dialog-title"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="responsive-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          {subtitle}
          </DialogContentText>
          
          <div className='flex flex-col py-4'>
            <div className='flex flex-col md:flex-row'>
              <TextField
                autoCapitalize='true'
                className='w-full md:w-4/6 '
                size='small'
                variant='filled'
                label="Nome"
                id="name"
                name="name"
                disabled={readonly}
                value={formik.values.name.toUpperCase()}
                onChange={(event => formik.setValues({...formik.values, name: event.target.value.toUpperCase()}))}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                className='w-full md:w-2/6 '
                size='small'
                variant='filled'
                label="Data de nascimento"
                id="birth"
                name="birth"
                type='date'
                lang='ptBR'
                disabled={readonly}
                value={formik.values.dateBirth}
                onChange={(event => formik.setValues({...formik.values, dateBirth: event.target.value}))}
                onBlur={formik.handleBlur}
                error={formik.touched.dateBirth && Boolean(formik.errors.dateBirth)}
                helperText={formik.touched.dateBirth && formik.errors.dateBirth}
              />
            </div>
            <div className='flex flex-col md:flex-row'>
              <TextField
                fullWidth
                size='small'
                variant="filled"
                label="Login"
                id="login"
                name="login"
                type="login"
                disabled={readonly}
                value={formik.values.login}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.login && Boolean(formik.errors.login)}
                helperText={formik.touched.login && formik.errors.login}
              />
              <TextField
                InputProps={{
                  endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      // onMouseDown={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>  
                  </InputAdornment>,
                }}
                fullWidth
                size='small'
                variant="filled"
                label="Senha"
                id="password"
                name="password"
                disabled={readonly}
                value={formik.values.password}
                type={showPassword ? 'text' :  'password'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </div>
            <div className='flex flex-col md:flex-row'>
              <TextField
                className='w-full md:w-1/3 '
                size='small'
                variant="filled"
                label="E-mail"
                id="email"
                name="email"
                type="email"
                disabled={readonly}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                className='w-full md:w-1/3'
                size='small'
                variant="filled"
                label="Celular"
                id="phone"
                name="phone"
                disabled={readonly}
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
              <TextField
                className='w-full md:w-1/3'
                size='small'
                variant="filled"
                label="Celular extra"
                id="extraPhone"
                name="extraPhone"
                disabled={readonly}
                value={formik.values.extraPhone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.extraPhone && Boolean(formik.errors.extraPhone)}
                helperText={formik.touched.extraPhone && formik.errors.extraPhone}
              />
            </div>
            <div className='flex flex-col md:flex-row'>
              <TextField
                multiline
                maxRows={3}
                fullWidth
                size='small'
                variant="filled"
                label="Tokens de notificação"
                id="notificationTokens"
                name="notificationTokens"
                disabled={readonly}
                value={formik.values.notificationTokens}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.notificationTokens && Boolean(formik.errors.notificationTokens)}
                helperText={formik.touched.notificationTokens && formik.errors.notificationTokens}
              />
            </div>
            <div className='flex flex-col md:flex-row pb-2'>
              <FormGroup>
                <FormControlLabel disabled={readonly} control={<Switch checked={formik.values.active} onClick={() => formik.setValues({...formik.values, active: !formik.values.active})} />} label="Ativo" />
              </FormGroup>
            </div>
          </div>
          
        </DialogContent>
        <DialogActions>
          {
            !readonly ?
            <Button variant='text' color='error' onClick={onCancelButtonClicked}>
              CANCELAR
            </Button> : null
          }
          <Button type='submit' onClick={() => {}} variant='contained' color='primary' autoFocus>
            {readonly ? 'FECHAR' : user ? 'ATUALIZAR' : 'SALVAR'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserForm;