import { BaseResponseType, CreatePermission, DeletePermission } from "../@types/Responses";
import { deleteOptionsWithBody, postOptions } from "./common";

const endpoint = '/api/permissions';

const createPermission = async (permission: CreatePermission) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, postOptions(JSON.stringify(permission)));
  return response.json();
}

const deletePermission = async (permission: DeletePermission) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, deleteOptionsWithBody(JSON.stringify(permission)));
  return response.json();
}

export const permission = {
  createPermission,
  deletePermission
};