import { MapContainer, Polyline, TileLayer } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, trackActions } from '../../store';
import { useEffect } from 'react';
import RouteMapMarker from './RouteMapMarker';

const DeviceRouteMap = () => {
  const dispatch = useDispatch();
  const positions = useSelector((state: RootState) => state.report.positions);
  const positionsList = Object.entries(positions).map(x => x[1]);

  const currentTrack = useSelector((state: RootState) => state.track.current);

  useEffect(() => {
    if (positionsList.length <= 0)
      return;

    const interval = setInterval(() => {
      dispatch(trackActions.updateCurrent())
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [positionsList]);

  return (
    <div className='w-full'>
      <MapContainer center={[-20.2821076,-40.3207233]} zoom={10} className='w-full h-full'>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {
          currentTrack !== null ?
          <>
            <RouteMapMarker
              latitude={currentTrack?.latitude as number}
              longitude={currentTrack?.longitude as number}
              rotationAngle={currentTrack?.course as number}
            />
            <Polyline pathOptions={{color: '#22a6b3'}} positions={positionsList.map(p => [p.latitude, p.longitude])}/>
          </>
          : null
        }
      </MapContainer>
    </div>
  );
};

export default DeviceRouteMap;