import { trackActions, type RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { FastForwardOutlined, FastRewindOutlined, PauseCircleFilled, PauseCircleOutline, PlayCircleFilled, PlayCircleOutline, RepeatOnRounded, RepeatOutlined, StopCircleOutlined, StopCircleRounded } from '@mui/icons-material';
import Button from '../Common/Button';
import { Tooltip } from '@mui/material';

const TrackRoute = () => {
  const dispatch = useDispatch();
  const track = useSelector((state: RootState) => state.track);

  return (
    <div className='flex flex-col'>
      <div className='flex border rounded-full text-sidebar-icon mx-4 justify-center'>
        <span onClick={() => dispatch(trackActions.play())}>
          <Tooltip title='Play'>
          {
            track.play ?
            <PlayCircleFilled className='w-6 h-6 mx-3 my-6 text-dark-blue-hover hover:cursor-pointer hover:text-dark-blue-hover rounded'/> :
            <PlayCircleOutline className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer hover:text-dark-blue-hover'/>
          }
          </Tooltip>
        </span>

      <span onClick={() => dispatch(trackActions.pause())}>
        <Tooltip title='Pause'>
        {
          track.pause ?
          <PauseCircleFilled className='w-6 h-6 mx-3 my-6 text-dark-blue-hover hover:cursor-pointer hover:text-dark-blue-hover rounded'/> :
          <PauseCircleOutline className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer hover:text-dark-blue-hover'/>
        }
        </Tooltip>
      </span>
      
      <span onClick={() => dispatch(trackActions.stop())}>
        <Tooltip title='Stop' placement="top">
        {
          track.stop ?
          <StopCircleRounded className='w-6 h-6 mx-3 my-6 text-dark-blue-hover hover:cursor-pointer hover:text-dark-blue-hover rounded'/> :
          <StopCircleOutlined className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer hover:text-dark-blue-hover'/>
        }
        </Tooltip>
      </span>
      
      <span onClick={() => dispatch(trackActions.fastRewind())}>
        <Tooltip title='Rebobinar' placement="top">
          <FastRewindOutlined className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer hover:text-dark-blue-hover'/>
        </Tooltip>
      </span>

      <span onClick={() => dispatch(trackActions.fastFoward())}>
        <Tooltip title='Acelerar'>
          <FastForwardOutlined className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer hover:text-dark-blue-hover'/>
        </Tooltip>
      </span>

      <span onClick={() => dispatch(trackActions.toggleRepeat())}>
        <Tooltip title='Repetir'>
        {
          track.repeat ?
          <RepeatOnRounded className='w-6 h-6 mx-3 my-6 text-dark-blue-hover hover:cursor-pointer hover:text-dark-blue-hover rounded'/> :
          <RepeatOutlined className='w-6 h-6 mx-3 my-6 text-sidebar-icon hover:cursor-pointer hover:text-dark-blue-hover'/>
        }
        </Tooltip>
      </span>
      </div>

      <button
        className='h-10 w-10 m-[-10px] self-center rounded bg-dark-blue hover:bg-dark-blue-hover hover:cursor-pointer'
      >
        <p className='text-white text-center font-roboto font-bold'>
          {track.increment >= 0 ? `X ${track.increment}` : `X ${track.increment}`}
        </p>
      </button>

    </div>
  );
};

export default TrackRoute;