import MainContentContainer from '../Common/MainContentContainer';
import Header from '../Common/Header';
import UserSidebar from './UserSidebar';
import UserTable from './UserTable';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useAuthContext } from '../../contexts/AuthenticationContext';
import { useUserContext } from '../../contexts/UserContext';
import { useEffect, useState } from 'react';
import { RootState, commandActions, deviceActions, notificationActions, userActions } from '../../store';
import UserCommand from './UserCommand';
import UserNotification from './UserNotification';
import UserVehicle from './UserVehicle';
import { TableActionType, UserFormValue } from '../../@types/Props';
import { User } from '../../@types/User';
import UserForm from './UserForm';
import AlertDialog from '../Common/AlertDialog';
import { useNotificationContext } from '../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import { useDeviceContext } from '../../contexts/DeviceContext';
import { useCommandContext } from '../../contexts/CommandContext';
import UserBill from './UserBill';
import UserClient from './UserClient';

const UserScreen = () => {
  const dispatch = useDispatch();
  const users = useSelector((state: RootState) => state.user.items);
  const notifications = useSelector((state: RootState) => state.notification.items);
  const devices = useSelector((state: RootState) => state.device.items);
  const commands = useSelector((state: RootState) => state.command.items);
  const selectedUserId = useSelector((state: RootState) => state.user.selectedUserId);
  const { user } = useAuthContext();
  const { generateNewUser, generateUpdateUser, updateUserById, createUser, deleteUserById, fetchUsersByUserId, fetchUsersSelectedUser, fetchUsers } = useUserContext();
  const { fetchNotificationsByUserId, fetchNotificationsSelectedUser } = useNotificationContext();
  const { fetchCommandsByUserId, fetchCommandsSelectedUser } = useCommandContext();
  const { fetchDevicesAndPositions, fetchDevicesSelectedUser } = useDeviceContext();
  const { t } = useTranslation();

  const [showUserAdd, setShowUserAdd] = useState(false);
  const [showUserEdit, setShowUserEdit] = useState(false);
  const [showUserData, setShowUserData] = useState(false);
  const [showUserDelete, setShowUserDelete] = useState(false);
  const [showUserCommand, setShowUserCommand] = useState(false);
  const [showUserNotification, setShowUserNotification] = useState(false);
  const [showUserDevice, setShowUserDevice] = useState(false);
  const [showUserClient, setShowUserClient] = useState(false);
  const [showUserBill, setShowUserBill] = useState(false);

  const toggleShowUserAdd = () => setShowUserAdd(!showUserAdd);
  const toggleShowUserEdit = () => setShowUserEdit(!showUserEdit);
  const toggleShowUserData = () => setShowUserData(!showUserData);
  const toggleShowUserDelete = () => setShowUserDelete(!showUserDelete);
  const toggleShowUserCommand = () => setShowUserCommand(!showUserCommand);
  const toggleShowUserNotification = () => setShowUserNotification(!showUserNotification);
  const toggleShowUserDevice = () => setShowUserDevice(!showUserDevice);
  const toggleShowUserClient = () => setShowUserClient(!showUserClient);
  const toggleShowUserBill = () => setShowUserBill(!showUserBill);

  const updateUser = {
    action: () => user.isAdmin ? fetchUsers() : fetchUsersByUserId(user.id),
  };

  const addUser = {
    action: () => toggleShowUserAdd(),
    onSubmit: (values: UserFormValue) => {
      const newUser = generateNewUser(values);
      createUser(newUser, addUser.close);
    },
    close: () => {
      dispatch(userActions.unSelect());
      toggleShowUserAdd();
    },
  };

  const editUser = {
    action: (item: TableActionType) => {
      dispatch(userActions.select((item as User).id))
      toggleShowUserEdit();
    },
    onSubmit: (values: UserFormValue) => {
      const selectedUser = selectedUserId !== null ? users[selectedUserId] : undefined;
      const newUser = generateUpdateUser(selectedUser as User, values);
      updateUserById(newUser, editUser.close);
    },
    close: () => {
      dispatch(userActions.unSelect());
      toggleShowUserEdit();
    },
  };

  const showUser = {
    action: (item: TableActionType) => {
      dispatch(userActions.select((item as User).id))
      toggleShowUserData();
    },
    onSubmit: (values: UserFormValue) => {
      dispatch(userActions.unSelect());
      toggleShowUserData();
    },
    close: () => {
      dispatch(userActions.unSelect());
      toggleShowUserData();
    },
  };

  const deleteUser = {
    action: (item: TableActionType) => {
      dispatch(userActions.select((item as User).id))
      toggleShowUserDelete()
    },
    close: () => {
      dispatch(userActions.unSelect());
      toggleShowUserDelete();
    },
    onAgree: () => {
      const selectedUser = selectedUserId !== null ? users[selectedUserId] : undefined;
      if (selectedUser)
        deleteUserById(selectedUser.id, deleteUser.close);
    }
  };

  const notificationUser = {
    action: (item: TableActionType) => {
      dispatch(userActions.select((item as User).id));
      fetchNotificationsSelectedUser((item as User).id);
      toggleShowUserNotification()
    },
    close: () => {
      batch(() => {
        dispatch(userActions.unSelect());
        dispatch(notificationActions.setSelectedNotifications(null));
      });
      toggleShowUserNotification();
    },
  }

  const commandUser = {
    action: (item: TableActionType) => {
      dispatch(userActions.select((item as User).id));
      fetchCommandsSelectedUser((item as User).id);
      toggleShowUserCommand()
    },
    close: () => {
      batch(() => {
        dispatch(userActions.unSelect());
        dispatch(commandActions.setSelectedCommands(null));
      });
      toggleShowUserCommand();
    },
  }

  const deviceUser = {
    action: (item: TableActionType) => {
      dispatch(userActions.select((item as User).id));
      fetchDevicesSelectedUser((item as User).id);
      toggleShowUserDevice()
    },
    close: () => {
      batch(() => {
        dispatch(userActions.unSelect());
        dispatch(deviceActions.setSelectedDevices(null));
      });
      toggleShowUserDevice();
    },
  }

  const clientUser = {
    action: (item: TableActionType) => {
      dispatch(userActions.select((item as User).id));
      fetchUsersSelectedUser((item as User).id);
      toggleShowUserClient()
    },
    close: () => {
      batch(() => {
        dispatch(userActions.unSelect());
        dispatch(userActions.setSelectedUsers(null));
      });
      toggleShowUserClient();
    },
  }

  const billUser = {
    action: (item: TableActionType) => {
      dispatch(userActions.select((item as User).id))
      toggleShowUserBill();
    },
    onSubmit: (values: UserFormValue) => {
      const selectedUser = selectedUserId !== null ? users[selectedUserId] : undefined;
      const newUser = generateUpdateUser(selectedUser as User, values);
      console.log(newUser)
      //updateUserById(newUser, editUser.close);
    },
    close: () => {
      dispatch(userActions.unSelect());
      toggleShowUserBill();
    },
  }

  useEffect(() => {
    if (user === null)
      return;
    
    if (Object.entries(users).length === 0) user.isAdmin ? fetchUsers() : fetchUsersByUserId(user.id);
    //if (Object.entries(notifications).length === 0) fetchNotificationsByUserId(user.id);
    if (Object.entries(devices).length === 0) fetchDevicesAndPositions(user.id);
    if (Object.entries(commands).length === 0) fetchCommandsByUserId(user.id);
  });

  useEffect(() => {
    if (user === null)
      return;
    
    if (Object.entries(notifications).length === 0) fetchNotificationsByUserId(user.id);
  }, []);

  return (
    <div className='overflow-hidden'>
      <Header title={`${t('tracking')} > ${t('users')}`}/>

      <MainContentContainer>

        <UserSidebar/>

        <UserTable
          users={users ? Object.entries(users).map((value, index) => value[1]) : []}
          actions={{
            showDataAction: showUser.action,
            updateAction: updateUser.action,
            addAction: addUser.action,
            deleteAction: deleteUser.action,
            editAction: editUser.action,
            commandAction: commandUser.action,
            // notificationAction: notificationUser.action,
            vehicleAction: deviceUser.action,
            clientAction: user.isAdmin ? clientUser.action : undefined,
            billAction: billUser.action,
          }}
        />

        <UserForm
          title='Adicionar usuário'
          subtitle='Insira as informações do usuário e clique em SALVAR para confirmar ou clique em CANCELAR para descartar.'
          onSubmit={addUser.onSubmit}
          user={undefined}
          opened={showUserAdd}
          readonly={false}
          close={addUser.close}
        />

        <UserForm
          title='Editar usuário'
          subtitle='Edite as informações do usuário e clique em ATUALIZAR para confirmar ou clique em CANCELAR para descartar. Deixe a senha em branco caso não queira alterá-la.'
          onSubmit={editUser.onSubmit}
          user={selectedUserId !== null ? users[selectedUserId] : undefined}
          opened={showUserEdit}
          readonly={false}
          close={editUser.close}
        />

        <UserForm
          title='Visualizar usuário'
          subtitle='Visualize as informações do usuário e clique em FECHAR para sair da tela.'
          onSubmit={showUser.onSubmit}
          user={selectedUserId !== null ? users[selectedUserId] : undefined}
          opened={showUserData}
          readonly={true}
          close={showUser.close}
        />

        <AlertDialog
          title='Remover usuário'
          text='Deseja remover o usuário? Essa ação é irreversível.'
          opened={showUserDelete}
          onAgree={deleteUser.onAgree}
          onDisagree={deleteUser.close}
          onClose={deleteUser.close}
        />
        
        {/* <UserNotification
          title='Notificações usuário'
          subtitle='Selecione as notificações do usuário e clique em SALVAR para confirmar ou clique em CANCELAR para descartar.'
          opened={showUserNotification}
          readonly={false}
          close={notificationUser.close}
        /> */}

        <UserCommand
          title='Comandos usuário'
          subtitle='Selecione os comandos do usuário e clique em SALVAR para confirmar ou clique em CANCELAR para descartar.'
          opened={showUserCommand}
          readonly={false}
          close={commandUser.close}
        />
        
        <UserVehicle
          title='Veículos usuário'
          subtitle='Selecione os veículos do usuário e clique em SALVAR para confirmar ou clique em CANCELAR para descartar.'
          opened={showUserDevice}
          readonly={false}
          close={deviceUser.close}
        />

        <UserClient
          title='Clientes usuário'
          subtitle='Selecione os clientes do usuário e clique em SALVAR para confirmar ou clique em CANCELAR para descartar.'
          opened={showUserClient}
          readonly={false}
          close={clientUser.close}
        />

        <UserBill
          title='Boletos usuário'
          subtitle='Lista de boletos do usuário'
          user={selectedUserId !== null ? users[selectedUserId] : undefined}
          onSubmit={billUser.onSubmit}
          opened={showUserBill}
          readonly={false}
          close={billUser.close}
        />

      </MainContentContainer>
    </div>
  );
};

export default UserScreen;