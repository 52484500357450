import LastUpdateTable from './LastUpdateTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useReportContext } from '../../contexts/ReportContext';
import NoGpsTable from './NoGpsTable';
import TotalGroupDevicesTable from './TotalGroupDevicesTable';
import GroupPayrollTable from './GroupPayrollTable';
import PositionTable from './PositionTable';

const ReportTable = () => {
  const devices = useSelector((state: RootState) => state.report.devices);
  const devicesList = Object.entries(devices).map(x => x[1]);
  const totalGroupDevices = useSelector((state: RootState) => state.report.totalGroupDevices);
  const totalGroupDevicesList = Object.entries(totalGroupDevices).map(x => x[1]);
  const groupPayroll = useSelector((state: RootState) => state.report.groupPayroll);
  const groupPayrollList = Object.entries(groupPayroll).map(x => x[1]);
  const positions = useSelector((state: RootState) => state.report.positions);
  const positionsList = Object.entries(positions).map(x => x[1]);
  const { reportType } = useReportContext();

  if (reportType?.value === 'lastUpdate') return <LastUpdateTable devices={devicesList} actions={{}} />
  if (reportType?.value === 'noGps') return <NoGpsTable devices={devicesList} actions={{}} />
  if (reportType?.value === 'totalGroupDevices') return <TotalGroupDevicesTable totalGroupDevices={totalGroupDevicesList} actions={{}} />
  if (reportType?.value === 'groupPayroll') return <GroupPayrollTable groupsPayroll={groupPayrollList} actions={{}} />
  if (reportType?.value === 'positions') return <PositionTable positions={positionsList} actions={{}} />
  else return null;

};
  
export default ReportTable;