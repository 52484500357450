import SidebarIcons from '../Common/SidebarIcons';
import type { RootState } from '../../store';
import { useSelector } from 'react-redux';
import GeofenceList from './GeofenceList';
import GeofenceSettings from './GeofenceSettings';

const GeofenceSidebar = () => {
  const show = useSelector((state: RootState) => state.sidebar.show);
  const showGeofenceSettings = useSelector((state: RootState) => state.geofence.showSettings);

  return (
    <>
      <aside className='border-r border-gray-200'>
        <SidebarIcons/>
      </aside>

      <aside className={show ? `bg-white` : `bg-white hidden`}>

        {
          showGeofenceSettings ? <GeofenceSettings /> : <GeofenceList />
        }
        
      </aside>
    </>
  );
};

export default GeofenceSidebar;