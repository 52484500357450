import { createContext, useContext } from "react";
import { useDispatch } from "react-redux";
import { UserContextType, ChildrenProps, UserFormValue } from "../@types/Props";
import { api } from "../api/api";
import { userActions } from "../store";
import { useSnackbarContext } from "./SnackbarContext";
import { Bill, User } from "../@types/User";
import { CreateUser, ResponseContentType, UpdateUser, UploadFile } from "../@types/Responses";
import { useAuthContext } from "./AuthenticationContext";
import dayjs from 'dayjs';

const UserContext = createContext<UserContextType | null>(null);

export const UserProvider = ({children} : ChildrenProps) => {
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const {
    sendRequest,
  } = useSnackbarContext();

  const generateNewUser = (
    values: UserFormValue,
    isAdmin: boolean = false,
    deviceLimit: number = 0,
    userLimit: number = 0) => {
    const newUser = {
      id: 0,
      readonly: false,
      administrator: isAdmin,
      map: "",
      latitude: 0,
      longitude: 0,
      zoom: 0,
      coordinateFormat: "",
      disableReports: false,
      expirationTime: null,
      deviceLimit: deviceLimit,
      userLimit: userLimit,
      deviceReadonly: false,
      limitCommands: false,
      fixedEmail: false,
      poiLayer: "",
      temporary: false,
      totpKey: "",
      attributes: {},
      
      name: values.name,
      email: values.login,
      phone: values.phone,
      password: values.password.length !== 0 ? values.password : null,
      disabled: !values.active,
    } as CreateUser;

    newUser.attributes = {
      ...newUser.attributes,
      email: values.email,
      celular: values.extraPhone,
      notificationTokens: values.notificationTokens,
      dateBirth: values.dateBirth ? dayjs(values.dateBirth).format('DD/MM/YYYY') : '',
    };

    return newUser;
  }

  const generateUpdateUser = (user: User, values: UserFormValue) => {
    const newUser = {
      id: user?.id,
      readonly: user?.readonly,
      administrator: user?.administrator,
      map: user?.map,
      latitude: user?.latitude,
      longitude: user?.longitude,
      zoom: user?.zoom,
      coordinateFormat: user?.coordinateFormat,
      disableReports: user?.disableReports,
      expirationTime: user?.expirationTime,
      deviceLimit: user?.deviceLimit,
      userLimit: user?.userLimit,
      deviceReadonly: user?.deviceReadonly,
      limitCommands: user?.limitCommands,
      fixedEmail: user?.fixedEmail,
      poiLayer: user?.poiLayer,
      temporary: user?.temporary,
      totpKey: user?.totpKey,
      attributes: user?.attributes,
      
      name: values.name,
      email: values.login,
      phone: values.phone,
      password: values.password.length !== 0 ? values.password : null,
      disabled: !values.active,
    } as UpdateUser;

    newUser.attributes = {
      ...newUser.attributes,
      email: values.email,
      celular: values.extraPhone,
      bills: values.bills,
      notificationTokens: values.notificationTokens,
      dateBirth: values.dateBirth ? dayjs(values.dateBirth).format('DD/MM/YYYY') : ''
    };

    return newUser;
  }

  const fetchUsersByUserId = async (userId: number) => {
    const request = async () => await api.user.fetchUsersByUserId(userId);
    const onSuccess = (content: ResponseContentType) => dispatch(userActions.refresh(content as User[]));
    sendRequest(request, onSuccess);
  }

  const fetchUsersSelectedUser = (userId: number) => {
    api.user.fetchUsersByUserId(userId)
    .then(response => {
      if (response.success)
        dispatch(userActions.setSelectedUsers(response.content as User[]));
    })
  };

  const fetchUsers = async () => {
    const request = async () => await api.user.fetchUsers();
    const onSuccess = (content: ResponseContentType) => dispatch(userActions.refresh(content as User[]));
    sendRequest(request, onSuccess);
  }

  const updateUserById = async (usr: UpdateUser, callback?: () => void) => {
    const request = async () => await api.user.updateUserById(usr);
    const onSuccess = (content: ResponseContentType) => {
      fetchUsers();
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Usuário atualizado com sucesso.");
  }

  const createUser = async (usr: CreateUser, callback?: () => void) => {
    const request = async () => await api.user.createUser(usr);
    const onSuccess = (content: ResponseContentType) => {
      fetchUsers();
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Usuário criado com sucesso.");
  }

  const deleteUserById = async (userId: number, callback?: () => void) => {
    const request = async () => await api.user.deleteUser(userId);
    const onSuccess = (content: ResponseContentType) => {
      fetchUsers();
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Usuário removido com sucesso.");
  }

  const createUserBill = async (billFile: File, callback?: (fileName: string) => void) => {
    const request = async () => await api.bill.upload({file: billFile} as UploadFile);
    const onSuccess = (content: ResponseContentType) => {
      fetchUsers();
      if (callback) callback(content as string);
    }
    sendRequest(request, onSuccess, "Boleto criado com sucesso.");
  }

  const updateUserBill = async (user: User, newBills: Bill[], callback?: () => void) => {
    let newUser = {
      ...user,
      attributes: {
        ...user.attributes,
        bills: newBills,
      }
    };
    updateUserById(newUser, callback);
  }

  const deleteFile = async (fileName: string, callback?: () => void) => {
    const request = async () => await api.bill.deleteBill(fileName);
    const onSuccess = (content: ResponseContentType) => {
      fetchUsers();
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Boleto deletado com sucesso.");
  }

  return (
    <UserContext.Provider
      value={{
        generateNewUser,
        generateUpdateUser,
        fetchUsersByUserId,
        fetchUsersSelectedUser,
        fetchUsers,
        updateUserById,
        createUser,
        deleteUserById,
        createUserBill,
        updateUserBill,
        deleteFile,
      }}
    >
      {children}
    </UserContext.Provider>
    );
}

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(
      'useUserContext must be used within a UserProvider'
    );
  }
  const {
    generateNewUser,
    generateUpdateUser,
    fetchUsersByUserId,
    fetchUsersSelectedUser,
    fetchUsers,
    updateUserById,
    createUser,
    deleteUserById,
    createUserBill,
    updateUserBill,
    deleteFile,
  } = context;
  return {
    generateNewUser,
    generateUpdateUser,
    fetchUsersByUserId,
    fetchUsersSelectedUser,
    fetchUsers,
    updateUserById,
    createUser,
    deleteUserById,
    createUserBill,
    updateUserBill,
    deleteFile,
  };
}