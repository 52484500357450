import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Geofence } from '../@types/Geofence';
import { Device } from '../@types/Device';

const DEFAULT_RADIUS = 100;

export interface GeofencesState {
  items: { [id: number] : Geofence };
  device: null | Device;
  radius: number;
  selectedGeofenceId: null | number;
  selectedGeofence: null | Geofence;
  showSettings: boolean;
}

const initialState: GeofencesState = {
  items: {},
  device: null,
  radius: DEFAULT_RADIUS,
  selectedGeofenceId: null,
  selectedGeofence: null,
  showSettings: false,
}

export const geofenceSlice = createSlice({
  name: 'geofence',
  initialState,
  reducers: {
    refresh: (state, action: PayloadAction<Geofence[]>) => {
      state.items = {};
      action.payload.forEach((item: Geofence) => state.items[item.id] = item);
    },
    update: (state, action: PayloadAction<Geofence[]>) => {
      action.payload.forEach((item: Geofence) => state.items[item.id] = item);
    },
    select: (state, action: PayloadAction<number>) => {
      state.selectedGeofenceId = action.payload;
    },
    unSelect: (state) => {
      state.selectedGeofenceId = null;
    },
    remove: (state, action: PayloadAction<number>) => {
      delete state.items[action.payload];
    },
    setSelectedGeofence: (state, action: PayloadAction<Geofence | null>) => {
      state.selectedGeofence = action.payload;
    },
    setDevice: (state, action: PayloadAction<Device | null>) => {
      state.device = action.payload;
    },
    setRadius: (state, action: PayloadAction<number>) => {
      state.radius = action.payload;
    },
    setShowSettings: (state, action: PayloadAction<boolean>) => {
      state.showSettings = action.payload;
    },
    reset: (state) => {
      state.items = {};
      state.device = null;
      state.radius = DEFAULT_RADIUS;
      state.selectedGeofenceId = null;
      state.selectedGeofence = null;
      state.showSettings = false;
    },
  },
})

// Action creators are generated for each case reducer function
export const geofenceActions = geofenceSlice.actions

export const geofenceReducer = geofenceSlice.reducer