import { BillFormValue, TableActionType, UserFormProps, UserFormValue } from '../../@types/Props';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from 'react-i18next';
import BillTable from '../Finance/BillTable';
import BillForm from '../Finance/BillForm';
import { useUserContext } from '../../contexts/UserContext';
import { Bill, User } from '../../@types/User';
import AlertDialog from '../Common/AlertDialog';

const MAX_BILLS = 6;

const UserBill = ({title, subtitle, user, opened, close, onSubmit}: UserFormProps) => {
  const users = useSelector((state: RootState) => state.user.items);
  const selectedUserId = useSelector((state: RootState) => state.user.selectedUserId);
  const selectedUser = selectedUserId !== null ? users[selectedUserId] : undefined;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { generateUpdateUser, updateUserById, createUserBill, updateUserBill, deleteFile } = useUserContext();
  //const { user } = useAuthContext();
  const { t } = useTranslation();

  const lessThenMaxBills =
    (
      !!selectedUserId && !!users && !!selectedUser && 
      !!selectedUser.attributes && !!selectedUser.attributes.bills &&
      selectedUser?.attributes?.bills?.length < MAX_BILLS
    ) 
    ||
    (
      !!selectedUserId && !!users && !!selectedUser && 
      !!selectedUser.attributes && selectedUser.attributes.bills === undefined
    );

  const [selectedBill, setSelectedBill] = useState<Bill | undefined>(undefined);

  const [showBillAdd, setShowBillAdd] = useState(false);
  const [showBillEdit, setShowBillEdit] = useState(false);
  const [showBillDelete, setShowBillDelete] = useState(false);

  const toggleShowBillAdd = () => setShowBillAdd(!showBillAdd);
  const toggleShowBillEdit = () => setShowBillEdit(!showBillEdit);
  const toggleShowBillDelete = () => setShowBillDelete(!showBillDelete);

  const addBill = {
    action: () => toggleShowBillAdd(),
    onSubmit: (values: BillFormValue) => {
      const selectedUser = selectedUserId !== null ? users[selectedUserId] : undefined;
      const createUserBillCallback = (fileName: string) => {
        console.log(fileName)
        let userFormValue = {
          name: selectedUser?.name,
          login: selectedUser?.email,
          email: selectedUser?.attributes.email,
          phone: selectedUser?.phone,
          extraPhone: selectedUser?.attributes.celular,
          password: selectedUser?.password ?? '',
          active: !selectedUser?.disabled,
          bills: selectedUser?.attributes.bills ? [
            ...selectedUser?.attributes.bills, { 
            dueDate: values.dueDate,
            paid: values.paid,
            url: fileName,
          }] : [{ 
            dueDate: values.dueDate,
            paid: values.paid,
            url: fileName,
          }]
        } as UserFormValue;
        
        const newUser = generateUpdateUser(selectedUser as User, userFormValue);
        updateUserById(newUser, () => setShowBillAdd(false));
      };
      createUserBill(values.file as File, createUserBillCallback);
    },
    close: () => toggleShowBillAdd()
  };

  const editBill = {
    action: (item: TableActionType) => {
      setSelectedBill(item as Bill);
      toggleShowBillEdit();
    },
    onSubmit: (values: BillFormValue) => {
      const selectedUser = selectedUserId !== null ? users[selectedUserId] : undefined;
      let userFormValue = {
        name: selectedUser?.name,
        login: selectedUser?.email,
        email: selectedUser?.attributes.email,
        phone: selectedUser?.phone,
        extraPhone: selectedUser?.attributes.celular,
        password: selectedUser?.password ?? '',
        active: !selectedUser?.disabled,
        bills: selectedUser?.attributes.bills ? [
          ...selectedUser.attributes.bills.filter(b => b !== selectedBill), {
          dueDate: values.dueDate,
          paid: values.paid,
          url: values.url,
        }] : [{ 
          dueDate: values.dueDate,
          paid: values.paid,
          url: values.url,
        }]
      } as UserFormValue;

      const newUser = generateUpdateUser(selectedUser as User, userFormValue);
      updateUserById(newUser, () => setShowBillEdit(false));
    },
    close: () => toggleShowBillEdit()
  };

  const deleteBill = {
    action: (item?: TableActionType) => {
      setSelectedBill(item as Bill);
      toggleShowBillDelete()
    },
    close: () => toggleShowBillDelete(),
    onAgree: () => {
      const selectedUser = selectedUserId !== null ? users[selectedUserId] : undefined;
      if (selectedUser && selectedBill && selectedUser.attributes.bills) {
        const billsDelete = selectedUser.attributes.bills.filter(b => b === selectedBill);
        const newBills = selectedUser.attributes.bills.filter(b => b !== selectedBill);
        billsDelete.forEach(b => {
          const billSplited = b.url?.split('/');
          if (billSplited)
            deleteFile(billSplited[billSplited.length - 1]);
        })
        updateUserBill(selectedUser, newBills, deleteBill.close);
      }
    }
  };

  const downloadBill = {
    action: (item: TableActionType) => {
      const bill = item as Bill;
      window.open(bill.url, '_blank');
    },
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={'lg'}
      open={opened}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
        <DialogContent>
          <DialogContentText>
          {subtitle}
          </DialogContentText>
          
          <BillTable
            actions={{
              addAction: lessThenMaxBills ? addBill.action : undefined,
              editAction: editBill.action,
              deleteAction: deleteBill.action,
              downloadBillAction: downloadBill.action,
            }}
            bills={user?.attributes.bills}
          />

          <BillForm
            title='Adicionar boleto'
            subtitle='Insira as informações do boleto e clique em SALVAR para confirmar ou clique em CANCELAR para descartar.'
            onSubmit={addBill.onSubmit}
            bill={undefined}
            opened={showBillAdd}
            readonly={false}
            close={addBill.close}
          />

          <BillForm
            title='Editar boleto'
            subtitle='Insira as informações do boleto e clique em SALVAR para confirmar ou clique em CANCELAR para descartar.'
            onSubmit={editBill.onSubmit}
            bill={selectedBill}
            opened={showBillEdit}
            readonly={false}
            close={editBill.close}
          />

          <AlertDialog
            title='Remover boleto'
            text='Deseja remover o boleto? Essa ação é irreversível.'
            opened={showBillDelete}
            onAgree={deleteBill.onAgree}
            onDisagree={deleteBill.close}
            onClose={deleteBill.close}
          />
          
        </DialogContent>
        <DialogActions>
          {
            fullScreen ?
            <Button variant='contained' color='error' onClick={close}>
              FECHAR
            </Button> : null
          }
        </DialogActions>
    </Dialog>
  );
};

export default UserBill;
