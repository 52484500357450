import { UpdateDeviceAccumulatorsProps } from '../../@types/Props';
import { useDeviceContext } from '../../contexts/DeviceContext';
import { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField, useMediaQuery, useTheme } from '@mui/material';
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from 'react-i18next';
import { UpdateDeviceAccumulator } from '../../@types/Responses';


const UpdateDeviceAccumulators = ({opened, close, title, subtitle, device}: UpdateDeviceAccumulatorsProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { updateAccumulator } = useDeviceContext();
  const { t } = useTranslation();

  const [totalDistance, setTotalDistance] = useState<number | undefined>(undefined);
  const [hours, setHours] = useState<number | undefined>(undefined);

  const onDialogClose = (event: object, reason: string) => {
    if (reason !== "backdropClick") close();
  };

  const onCancelButtonClicked = useCallback(() => {
    setTotalDistance(undefined);
    setHours(undefined);
    close();
  }, [setTotalDistance, setHours, close]);

  const onSaveButtonClicked = useCallback(() => {
    if (!!device) {
      const updateDeviceAccumulator = {
        deviceId: device?.id as number,
        totalDistance: totalDistance !== undefined ? totalDistance * 1000 : null,
        hours: hours !== undefined ? hours * 3600000 : null,
      } as UpdateDeviceAccumulator;
      updateAccumulator(device?.id as number, updateDeviceAccumulator, onCancelButtonClicked);
    }
  }, [device, totalDistance, hours, updateAccumulator, onCancelButtonClicked]);

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen={fullScreen}
      open={opened}
      onClose={onDialogClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
        <DialogContent>
          <DialogContentText>
          {subtitle}
          </DialogContentText>
          <div className='flex flex-col py-4'>
            <div className='flex flex-col md:flex-row'>
              <TextField
                type='number'
                className='w-full md:w-1/2'
                size='small'
                variant="filled"
                label="Hodômetro (km)"
                id="odometer"
                name="odometer"
                placeholder='Ex.: 163500'
                value={totalDistance}
                onChange={(e) => setTotalDistance(parseFloat(e.target.value))}
              />
              <TextField
                type='number'
                className='w-full md:w-1/2'
                size='small'
                variant="filled"
                label="Horímetro (h)"
                id="hours"
                name="hours"
                placeholder='Ex.: 86400'
                value={hours}
                onChange={(e) => setHours(parseInt(e.target.value))}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='error' onClick={onCancelButtonClicked}>
            CANCELAR
          </Button>
          <Button type='submit' onClick={onSaveButtonClicked} variant='contained' color='primary' autoFocus>
            ENVIAR
          </Button>
        </DialogActions>
    </Dialog>
  );
};

export default UpdateDeviceAccumulators;