import { Device } from '../../@types/Device';
import 'leaflet-rotatedmarker';
import RotatedMarker from '../Common/RotatedMarker';
import MainMapPopup from './MainMapPopup';
import { getIcon } from '../../util/util';

type DeviceMarkerProps = {
  device: Device;
};

const DeviceMarker = ({device} : DeviceMarkerProps) => {
  const pos = device.position;
  return (
    pos ?
    <RotatedMarker
      position={[pos.latitude, pos.longitude]}
      icon={getIcon(device.category)}
      rotationAngle={pos.course}
      rotationOrigin={"center"}
      deviceId={device.id}
    >
      <MainMapPopup device={device}/>
    </RotatedMarker>
    : null
  )
};

export default DeviceMarker;