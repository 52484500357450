import SidebarIcons from '../Common/SidebarIcons';
import type { RootState } from '../../store';
import { useSelector } from 'react-redux';
import SearchReport from './SearchReport';

const ReportSidebar = () => {
  const show = useSelector((state: RootState) => state.sidebar.show)

  return (
    <>
      <aside className='border-r border-gray-200'>
        <SidebarIcons/>
      </aside>

      <aside className={show ? `bg-white` : `bg-white hidden`}>
        <SearchReport/>
      </aside>
    </>
  );
};

export default ReportSidebar;