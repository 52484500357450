import { Circle, MapContainer, Polyline, TileLayer } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useEffect } from 'react';
import GeofenceMapMarker from './GeofenceMapMarker';
import GeofenceMapDeviceFocus from './GeofenceMapDeviceFocus';
import { getGeofenceLatitude, getGeofenceLongitude, getGeofenceRadius } from '../../util/util';
import GeofenceMapGeofenceFocus from './GeofenceMapGeofenceFocus';

const GeofenceMap = () => {
  const geofences = useSelector((state: RootState) => state.geofence.items);
  const geofencesList = Object.entries(geofences).map(x => x[1]);
  const selectedGeofence = useSelector((state: RootState) => state.geofence.selectedGeofence);
  const device = useSelector((state: RootState) => state.geofence.device);
  const radius = useSelector((state: RootState) => state.geofence.radius);

  return (
    <div className='w-full'>
      <MapContainer center={[-20.2821076,-40.3207233]} zoom={10} className='w-full h-full'>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {
          device !== null ?
          <>
            <GeofenceMapMarker device={device} />
            <GeofenceMapDeviceFocus focusedDevice={device} />
            <Circle
              center={[device.position?.latitude as number, device.position?.longitude as number]}
              pathOptions={{ color: '#22a6b3', fillColor: '#22a6b3' }}
              radius={radius}
            />
          </>
          : 
          <>
            <GeofenceMapGeofenceFocus focusedGeofence={selectedGeofence} />
            {
              geofencesList.map(g => 
              <Circle
                key={g.id}
                center={[getGeofenceLatitude(g.area), getGeofenceLongitude(g.area)]}
                pathOptions={{ color: '#22a6b3', fillColor: '#22a6b3' }}
                radius={getGeofenceRadius(g.area)}
              />)
            }
          </>
        }
      </MapContainer>
    </div>
  );
};

export default GeofenceMap;