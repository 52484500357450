import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Group } from '../@types/Group';

export interface GrpouState {
  items: { [id: number] : Group };
  selectedId: null | number;
}

const initialState: GrpouState = {
  items: {},
  selectedId: null,
}

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    refresh: (state, action: PayloadAction<Group[]>) => {
      state.items = {};
      action.payload.forEach((item: Group) => state.items[item.id] = item);
    },
    update : (state, action: PayloadAction<Group>) => {
      state.items[action.payload.id] = action.payload;
    },
    updateMany: (state, action: PayloadAction<Group[]>) => {
      action.payload.forEach((item: Group) => state.items[item.id] = item);
    },
    select: (state, action: PayloadAction<number | null>) => {
      state.selectedId = action.payload;
    },
    remove: (state, action: PayloadAction<number>) => {
      delete state.items[action.payload];
    },
    reset: (state) => {
      state.items = {};
      state.selectedId = null;
    },
  },
})

// Action creators are generated for each case reducer function
export const groupActions = groupSlice.actions

export const groupReducer = groupSlice.reducer