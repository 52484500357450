import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { US } from 'country-flag-icons/react/1x1'
import { BR } from 'country-flag-icons/react/1x1'
import { useState } from 'react';
import { useTranslationContext } from '../../contexts/TranslationContext';

const LanguageSelector = () => {
  const { changeLanguage, getLanguage } = useTranslationContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='flex px-4'>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Language">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 26, height: 26, backgroundColor: 'white' }}>
            {
              getLanguage() === 'en' ? <US title="United States" className='w-6 h-6 rounded-full'/> :
              getLanguage() === 'pt_BR' ? <BR title="Brasil" className='w-6 h-6 rounded-full'/> :
              <BR title="Brasil" className='w-6 h-6 rounded'/>
            }
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={() => {
            changeLanguage('en');
            handleClose();
          }}
        >
          <US title="United States" className='w-6 h-6 rounded-full mr-2'/> English
        </MenuItem>
        <MenuItem onClick={() => {
            changeLanguage('pt_BR');
            handleClose();
          }}
        >
          <BR title="Brasil" className='w-6 h-6 rounded-full mr-2'/> Português
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSelector;