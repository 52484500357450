import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Position } from '../@types/Position';
import { Device } from '../@types/Device';

export interface EventsState {
  positions: { [id: number] : Position };
  devices: { [id: number] : Device };
}

const initialState: EventsState = {
  positions: {},
  devices: {},
}

export const eventslice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    addPosition: (state, action: PayloadAction<Position>) => {
      const position = action.payload;
      if (position !== null && position !== undefined)
        state.positions[position.id] = position;
    },
    addDevice: (state, action: PayloadAction<Device>) => {
      const device = action.payload;
      if (device !== null && device !== undefined)
        state.devices[device.id] = device;
    },
    clearPositions: (state) => {
      state.positions = {};
    },
    clearDevices: (state) => {
      state.devices = {};
    },
    reset: (state) => {
      state.devices = {};
      state.positions = {};
    },
  },
})

// Action creators are generated for each case reducer function
export const eventActions = eventslice.actions

export const eventReducer = eventslice.reducer