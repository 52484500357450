import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { useEffect, useMemo } from 'react';
import { Geofence } from '../../@types/Geofence';
import { getGeofenceLatitude, getGeofenceLongitude } from '../../util/util';

type GeofenceMapGeofenceFocusProps = {
  focusedGeofence: Geofence | null;
}

const streets =  L.tileLayer(`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`);
const satellite = L.tileLayer(`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`);

const GeofenceMapGeofenceFocus = ({focusedGeofence}: GeofenceMapGeofenceFocusProps) => {
  const map = useMap();
  
  useEffect(() => {
    if (focusedGeofence !== null) {
      const focusedMarkerBounds = L.latLngBounds(L.latLng(getGeofenceLatitude(focusedGeofence.area), getGeofenceLongitude(focusedGeofence.area)), L.latLng(getGeofenceLatitude(focusedGeofence.area), getGeofenceLongitude(focusedGeofence.area)));
      map.fitBounds(focusedMarkerBounds);
    }
  }, [focusedGeofence])

  useEffect(() => {
    var baseMaps = {
      "Satélite": satellite,
      "Mapa": streets,
      //"Tráfego": traffic,
    };

    if (!map.hasLayer(streets)) {
      //map.addLayer(satellite);
      map.addLayer(streets);
      L.control.layers(baseMaps).addTo(map);
    }
  }, [])

  return (
    <></>
  );
};

export default GeofenceMapGeofenceFocus;