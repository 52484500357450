import { createContext, useContext } from "react";
import { useDispatch } from "react-redux";
import { DeviceContextType, ChildrenProps, DeviceFormValue } from "../@types/Props";
import { api } from "../api/api";
import { deviceActions } from "../store";
import { useSnackbarContext } from "./SnackbarContext";
import { CreateDevice, ResponseContentType, UpdateDevice, UpdateDeviceAccumulator } from "../@types/Responses";
import { Device } from "../@types/Device";
import { speedToKnots } from "../util/util";

const DeviceContext = createContext<DeviceContextType | null>(null);

export const DeviceProvider = ({children} : ChildrenProps) => {
  const dispatch = useDispatch();
  const {
    sendRequest,
  } = useSnackbarContext();

  const generateNewDevice = (values: DeviceFormValue) => {
    const newDevice = {
      name: values.name,
      uniqueId: values.uniqueId,
      groupId: values.groupId,
      phone: values.phone,
      model: values.model,
      category: values.category,
      attributes: {
        placa: values.placa,
        modelo: values.modelo,
        ano: parseInt(values.ano),
        comandoBloqueio: values.comandoBloqueio,
        speedLimit: speedToKnots(parseFloat(values.speedLimit), 'kmh'),
        comodato: values.comodato,
        deviceImage: values.deviceImage,
      }
    } as CreateDevice;
    return newDevice;
  }

  const generateUpdateDevice = (device: Device, values: DeviceFormValue) => {
    const newDevice = {
      id: device?.id,
      status: device?.status,
      disabled: device?.disabled,
      contact: device?.contact,
      calendarId: device?.calendarId,
      expirationTime: device?.expirationTime,
      name: values.name,
      uniqueId: values.uniqueId,
      groupId: values.groupId,
      phone: values.phone,
      model: values.model,
      category: values.category,
      attributes: {
        placa: values.placa,
        modelo: values.modelo,
        ano: parseInt(values.ano),
        comandoBloqueio: values.comandoBloqueio,
        speedLimit: speedToKnots(parseFloat(values.speedLimit), 'kmh'),
        comodato: values.comodato,
        deviceImage: values.deviceImage,
      },
    } as UpdateDevice;

    return newDevice;
  }

  const fetchDevicesAndPositions = async (userId: number, callback?: () => void) => {
    const deviceResponse = await api.device.fetchDevices();

    if (deviceResponse.success) {
      //var semPos = deviceResponse.content.filter(x => x.position === null || x.position === undefined);
      //console.log(semPos.length);
      //deviceResponse.content.forEach(d => console.log(d.position?.attributes.ignition, !!d.position?.attributes.ignition))
      // deviceResponse.content.forEach(d => {
      //   if (d.name === 'JOSE LUIZ RODRIGUES LIMA')
      //     console.log(d.id, d.name, d.positionId, d.position)
      // })
      //console.log("device count", deviceResponse.content.length)
      dispatch(deviceActions.refresh(deviceResponse.content));
      if (callback) callback();
    }
  }

  const fetchDevicesSelectedUser = (userId: number) => {
    api.device.fetchDevicesByUserId(userId)
    .then(response => {
      if (response.success)
        dispatch(deviceActions.setSelectedDevices(response.content as Device[]));
    })
  };

  const updateDeviceById = async (userId: number, device: UpdateDevice, callback?: () => void) => {
    const request = async () => await api.device.updateDeviceById(device);
    const onSuccess = (content: ResponseContentType) => {
      fetchDevicesAndPositions(userId);
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Veículo atualizado com sucesso.");
  }

  const createDevice = async (userId: number, device: CreateDevice, callback?: () => void) => {
    const request = async () => await api.device.createDevice(device);
    const onSuccess = (content: ResponseContentType) => {
      fetchDevicesAndPositions(userId);
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Veículo criado com sucesso.");
  }

  const deleteDeviceById = async (userId: number, deviceId: number, callback?: () => void) => {
    const request = async () => await api.device.deleteDevice(deviceId);
    const onSuccess = (content: ResponseContentType) => {
      fetchDevicesAndPositions(userId);
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Veículo removido com sucesso.");
  }

  const updateAccumulator = async (userId: number, updateAccumulator: UpdateDeviceAccumulator, callback?: () => void) => {
    const request = async () => await api.device.updateAccumulator(updateAccumulator);
    const onSuccess = (content: ResponseContentType) => {
      fetchDevicesAndPositions(userId);
      if (callback) callback();
    }
    sendRequest(request, onSuccess, "Contadores atualizados com sucesso.");
  }

  return (
    <DeviceContext.Provider
      value={{
        generateNewDevice,
        generateUpdateDevice,
        fetchDevicesAndPositions,
        fetchDevicesSelectedUser,
        updateDeviceById,
        createDevice,
        deleteDeviceById,
        updateAccumulator,
      }}
    >
      {children}
    </DeviceContext.Provider>
    );
}

export const useDeviceContext = () => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error(
      'useDeviceContext must be used within a DeviceProvider'
    );
  }
  const {
    generateNewDevice,
    generateUpdateDevice,
    fetchDevicesAndPositions,
    fetchDevicesSelectedUser,
    updateDeviceById,
    createDevice,
    deleteDeviceById,
    updateAccumulator,
  } = context;
  return {
    generateNewDevice,
    generateUpdateDevice,
    fetchDevicesAndPositions,
    fetchDevicesSelectedUser,
    updateDeviceById,
    createDevice,
    deleteDeviceById,
    updateAccumulator,
  };
}