import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Command } from '../@types/Command';

export interface CommandsState {
  items: { [id: number] : Command };
  selectedCommandId: null | number;
  selectedCommands: null | Command[];
}

const initialState: CommandsState = {
  items: {},
  selectedCommandId: null,
  selectedCommands: null,
}

export const commandSlice = createSlice({
  name: 'command',
  initialState,
  reducers: {
    refresh: (state, action: PayloadAction<Command[]>) => {
      state.items = {};
      action.payload.forEach((item: Command) => state.items[item.id] = item);
    },
    update: (state, action: PayloadAction<Command[]>) => {
      action.payload.forEach((item: Command) => state.items[item.id] = item);
    },
    select: (state, action: PayloadAction<number>) => {
      state.selectedCommandId = action.payload;
    },
    unSelect: (state) => {
      state.selectedCommandId = null;
    },
    remove: (state, action: PayloadAction<number>) => {
      delete state.items[action.payload];
    },
    setSelectedCommands: (state, action: PayloadAction<Command[] | null>) => {
      state.selectedCommands = action.payload;
    },
    reset: (state) => {
      state.items = {};
      state.selectedCommandId = null;
      state.selectedCommands = null;
    },
  },
})

// Action creators are generated for each case reducer function
export const commandActions = commandSlice.actions

export const commandReducer = commandSlice.reducer