import { createContext, useContext } from "react";
import { useDispatch } from "react-redux";
import { GeofenceContextType, ChildrenProps, GeofenceFormValue } from "../@types/Props";
import { geofenceActions } from "../store";
import { api } from "../api/api";
import { Geofence } from "../@types/Geofence";
import { CreateGeofence, ResponseContentType } from "../@types/Responses";
import { useSnackbarContext } from "./SnackbarContext";

const GeofenceContext = createContext<GeofenceContextType | null>(null);

export const GeofenceProvider = ({children} : ChildrenProps) => {
  const dispatch = useDispatch();
  const {
    sendRequest,
  } = useSnackbarContext();

  const fetchGeofences = () => {
    api.geofence.fetchGeofences()
    .then(response => {
      if (response.success)
        dispatch(geofenceActions.refresh(response.content as Geofence[]));
    })
  };

  const fetchGeofencesByDeviceId = (deviceId: number) => {
    api.geofence.fetchGeofencesByDeviceId(deviceId)
    .then(response => {
      if (response.success)
        dispatch(geofenceActions.refresh(response.content as Geofence[]));
    })
  };

  const createGeofence = async (geofence: CreateGeofence, callback?: (content: ResponseContentType) => void) => {
    const request = async () => await api.geofence.createGeofence(geofence);
    const onSuccess = (content: ResponseContentType) => {
      // fetchGeofences();
      if (callback) callback(content);
    }
    sendRequest(request, onSuccess, "Geocerca criada com sucesso.");
  }

  const deleteGeofenceById = async (GeofenceId: number, callback?: (content: ResponseContentType) => void) => {
    const request = async () => await api.geofence.deleteGeofence(GeofenceId);
    const onSuccess = (content: ResponseContentType) => {
      fetchGeofences();
      if (callback) callback(content);
    }
    sendRequest(request, onSuccess, "Geocerca removida com sucesso.");
  }

  // const updateGeofenceById = async (Geofence: UpdateGeofence, callback?: () => void) => {
  //   const request = async () => await api.geofence.updateGeofenceById(Geofence);
  //   const onSuccess = (content: ResponseContentType) => {
  //     fetchGeofences();
  //     if (callback) callback();
  //   }
  //   sendRequest(request, onSuccess, "Notificação atualizada com sucesso.");
  // }

  return (
    <GeofenceContext.Provider
      value={{
        // generateCreateGeofence,
        // generateUpdateGeofence,
        fetchGeofences,
        fetchGeofencesByDeviceId,
        // fetchGeofencesSelectedUser,
        // fetchGeofencesSelectedDevice,
        createGeofence,
        deleteGeofenceById,
        // updateGeofenceById,
      }}
    >
      {children}
    </GeofenceContext.Provider>
    );
}

export const useGeofenceContext = () => {
  const context = useContext(GeofenceContext);
  if (!context) {
    throw new Error(
      'useGeofenceContext must be used within a GeofenceProvider'
    );
  }
  const {
    // generateCreateGeofence,
    // generateUpdateGeofence,
    fetchGeofences,
    fetchGeofencesByDeviceId,
    // fetchGeofencesSelectedUser,
    // fetchGeofencesSelectedDevice,
    createGeofence,
    deleteGeofenceById,
    // updateGeofenceById,
  } = context;
  return {
    // generateCreateGeofence,
    // generateUpdateGeofence,
    fetchGeofences,
    fetchGeofencesByDeviceId,
    // fetchGeofencesSelectedUser,
    // fetchGeofencesSelectedDevice,
    createGeofence,
    deleteGeofenceById,
    // updateGeofenceById,
  };
}