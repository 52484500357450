import { ChildrenProps } from '../../@types/Props';

const TableContainer = ({children}: ChildrenProps) => {
  return (
    <div className='overflow-y-auto select-none p-2 w-full'>
      {children}
    </div>
  );
};

export default TableContainer;