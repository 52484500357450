import { ThemeProvider, createTheme } from '@mui/material';
import { ChildrenProps } from '../../@types/Props';

const theme = createTheme({
  palette: {
    primary: {
      main: '#22a6b3',
      contrastText: '#ffffff'
    },
  },
  typography: {
    fontFamily: 'Roboto'
  },
  components: {
    // Name of the component
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '0.2rem'
        }
      }
    },
  },
});

// declare module '@mui/material/styles' {
//   interface Palette {
//     darkBlue: Palette['primary'];
//   }

//   // allow configuration using `createTheme`
//   interface PaletteOptions {
//     darkBlue?: PaletteOptions['primary'];
//   }
// }

// // @babel-ignore-comment-in-output Update the Button's color prop options
// declare module '@mui/material/Button' {
//   interface ButtonPropsColorOverrides {
//     darkBlue: true;
//   }
// }

// declare module '@mui/material/IconButton' {
//   interface IconButtonPropsColorOverrides {
//     darkBlue: true;
//   }
// }

const Theme = ({children}: ChildrenProps) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default Theme;