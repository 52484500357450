import { formatDate, speedFromKnots } from '../../util/util';
import 'leaflet-rotatedmarker';
import { Divider, Grid, Typography } from '@mui/material';
import { PowerSettingsNewRounded, RoomOutlined, SpeedRounded } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const RouteInfo = () => {
  const pos = useSelector((state: RootState) => state.track.current);

  return (
    <>
    {
      pos !== null && pos !== undefined ?
      (
      <div className='m-4 mt-6 h-auto w-80 select-none p-4'>
        <Grid container justifyContent={'space-between'} style={{flexFlow:"row", paddingBottom: 5}}>
          <PowerSettingsNewRounded
            style={
              pos.attributes !== null && pos.attributes !== undefined && 
              pos.attributes.ignition ? {color: "limegreen"} : {color: "red"}
            }
          />
          <span style={{flexFlow:"column"}}>
            <Typography style={{fontSize: 14}}>
              Horário do GPS
            </Typography>
            <Typography style={{fontSize: 10, color: "gray"}}>
              {formatDate(pos.deviceTime)}
            </Typography>
          </span>
          <span style={{flexFlow:"column", color: "lightseagreen"}}>
            <SpeedRounded />
            <Typography
              style={{fontSize: 10, margin: -4}}
            >
              {
                pos.speed !== 0 ?
                `${speedFromKnots(pos.speed, 'kmh')} km/h`
                :
                '0 km/h'
              } 
            </Typography>
          </span>
        </Grid>
        <Divider />
        <Grid container justifyContent={"space-evenly"} style={{flexFlow:"row", marginTop: 10}}>
          <RoomOutlined/>
          <Typography variant="inherit" style={{marginLeft: 15, marginTop: 0, marginBottom: 0}}>
            {
            pos.address !== null && pos.address !== undefined ?
            pos.address.replace("Brazil", "Brasil") :
            "Não foi possível obter o endereço a partir dessa coordenada"
            }
          </Typography>
          
        </Grid>
      </div>
      ) : null
    }
    </>
  )
};

export default RouteInfo;