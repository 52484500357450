import { useSelector } from 'react-redux';
import { RootState, geofenceActions } from '../../store';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useGeofenceContext } from '../../contexts/GeofenceContext';
import { useTranslation } from 'react-i18next';
import GeofenceCard from './GeofenceCard';
import Button from '../Common/Button';
import { useDispatch } from 'react-redux';

const GeofenceList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const geofences = useSelector((state: RootState) => state.geofence.items);
  const geofencesCount = Object.entries(geofences).length; 
  const geofencesList = Object.entries(geofences).map(x => x[1]);

  const { fetchGeofences } = useGeofenceContext();

  const [timedOut, setTimedOut] = useState(false);
  
  useEffect(() => {
    window.setTimeout(() => setTimedOut(true), 2000);
  })

  useEffect(() => {
    fetchGeofences();
  }, []);

  return (
    <div className='m-4 h-auto w-80 select-none'>
      <Button
        action={() => dispatch(geofenceActions.setShowSettings(true))}
        label={t('createGeofence')}
        //disabled={name === undefined || name.length === 0 || device === null || device === undefined || device.position === null || device.position === undefined}
      />
      
      <h1 className='pt-4 pb-4 text-center font-bold text-sidebar-icon'>{t('geofences')}</h1>
      {
        geofencesCount === 0 ?
          timedOut ?
            <p className='text-center'>{t('noGeofencesRegistered')}</p>
            :
            [0,1,2,3,4,5,6,7,8,9,10,11,12].map((n,i) => <Skeleton key={i} containerClassName="flex h-14 w-full pt-2"/>) 
          :
          <div className='overflow-y-auto select-none' style={{
            height: 'calc(100vh - 10.5rem)'
          }}>
            {geofencesList.map((g, i) => <GeofenceCard key={i} geofence={g} />)}
          </div>
      }
    </div>
  );
};

export default GeofenceList;