import SidebarIcons from '../Common/SidebarIcons';
import type { RootState } from '../../store';
import { useSelector } from 'react-redux';
import SearchRoute from './SearchRoute';
import TrackRoute from './TrackRoute';
import RouteInfo from './RouteInfo';

const RouteMapSidebar = () => {
  const show = useSelector((state: RootState) => state.sidebar.show)

  return (
    <>
      <aside className='border-r border-gray-200'>
        <SidebarIcons/>
      </aside>

      <aside className={show ? `bg-white` : `bg-white hidden`}>
        <SearchRoute />

        <TrackRoute/>

        <RouteInfo/>
      </aside>
    </>
  );
};

export default RouteMapSidebar;